import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import Remarks from "./Remarks";
import PDFViewer from 'pdf-viewer-reactjs-bulma-wrapped';
import Student_PDF from "./Student_Answer_PDF";
import "./assignment.css"
function Student_Answer(props)
{


  const [data, setData] = useState([]);
  const [pdf, setPDF] = useState([]);

  const [file, setFile] = useState("");

  const [loaded,setLoading]=useState(false);
  const [questions,setQuestion]=useState([]);
  const [answer,setAnswers]=useState([]);




  useEffect(()=>
  {
   document.getElementsByClassName('side-menu')[0].style.display="none"
   document.getElementsByClassName('smallbar')[0].style.display="block"
   if((localStorage.getItem("student_name")===null) ||(localStorage.getItem("student_name")==="undefined")) localStorage.setItem("student_name",props.location.s_name)
   
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    const id = props.match.params.id;
    const sid = props.match.params.sid;
   
  axios.get(`${process.env.REACT_APP_API_URL}learning/api/test-series-pdf-answers/?testseries=${id}&user_id=${sid}`, {headers : headers})
  .then(response => {
    
    setData(response.data.data)

    setLoading(false)
  })
  .catch(err => {
    console.log(err)
  });
  return () => {
    document.getElementsByClassName('side-menu')[0].style.display="block"
    document.getElementsByClassName('smallbar')[0].style.display="none"
   
  }
  }
,[])

useEffect(()=>
{
  setLoading(true)
  data.map(d=>(
    (d.answersheet!='N/A')?(
      setPDF(<PDFViewer  
      navbarOnTop={true}
      canvasCss='customCanvas'
      minScale={0.5}
      scaleStep={0.5}
      document={{ url: `https://cors-anywhere.herokuapp.com/${d.answersheet}`,}} />)
      ):setPDF("No file found")
  ))
  // setPDF(<PDFViewer hideNavbar={true}  document={{ url: `https://cors-anywhere.herokuapp.com/${d.answersheet}`,}} />
  // setPDF(<PDFViewer hideNavbar={true}  document={{ url: `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_URL}/${d.answersheet}`,}} />)

}
,[data])


  const answers =(event)=>
  {
    setAnswers({...answer,[event.target.name]:event.target.value})
  }

  const onFileChange = (event) =>
  {
    if(event.target.files[0]!=undefined){
    setData(event.target.files[0].name);
    setFile(event.target.files[0]);
    }
    else{
      setData("None")
      setFile("");

    }
    
  }
const reset=(event)=>
{
setData("None")
setFile("");


}
  const submit = (event)=>
  {
   
     const token = localStorage.Ltoken

      const headers = {
          "AUTHORIZATION": `Token ${token}`
          
        }
        if(typeof(file.name)!="undefined"){

        const formData = new FormData();
        formData.append("answer_sheet",file,file.name)
        axios.post( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.match.params.id}`,formData, {headers:headers} )
        .then( response => {
             if(response.statusText==='OK')
             {
               props.history.push("/assignment");


             }
        } )
        .catch(error => {
            console.log(error);
        });
      }
      
  }

if(loaded)
return(
<div className="test">  
<main className="container-fluid mt-5 ">
                <h1 className="text-center" style={{fontSize:"22px"}}>Assignment - {localStorage.getItem('assign_name')}</h1>
                <h1 className="text-center" style={{fontSize:"22px"}}>Student Name - {localStorage.getItem("student_name")}</h1>

                <ul className="list-group list-group-horizontal">
                {/* <Link to={`/assignment/${props.match.params.id}`}><li key="1" className="list-group-item " >Question</li></Link> */}
                <Link to={`/assignment/${props.match.params.id}/students`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>

</ul>
</main>

<div className="row">
  <div className="col-2 mt-3">
{data.map(d=><a className="download_button" target="_blank" href={d.answersheet}><i class="fas fa-file-download fa-2x" style={{float:"left"}}></i>Student Answer Sheet</a>)}
                    </div>
                    <div className="col-8 mt-3">
                   </div>
                    <div className="col-md-8 col-sm-8  text-center">
                    <div>
                                          
                           
                            {pdf} 
                            
                            </div>
                            <Student_PDF id= {props.match.params.id} sid= {props.match.params.sid}/>
                            </div>                             
                                        
                              
<div className="col-md-4 col-sm-4  text-center">

                     <Remarks key="test" history={props.history} id= {props.match.params.id} sid= {props.match.params.sid}/>  
                   </div>
                </div>

</div>
  )
  else
  return("Loading...")

  
}


export default Student_Answer;