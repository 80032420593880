import React from 'react';

const table = (props) => (


                <tbody>
                <tr className="solid">
                    <th scope="row">{props.sno}</th>
                    {/* <td>{props.name}</td> */}
                    <td>{props.company}</td>
                    <td>{props.city}</td>
                    {/* <td>{props.first_name}</td> */}
                    <td>{props.name}</td>
                    <td>{props.mobile}</td>
                    <td>{props.email}</td>
                    {/* <td>{props.username}</td>
                    <td>{props.branch}</td>
                    <td>{props.license}</td> */}
                    
                    

                    
                </tr>
            </tbody>


)

export default table;