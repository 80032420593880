import React from 'react';
import axios from 'axios';
import LicenseList from './LicenseList';
import '../Branch/BranchCreate.css';
import './Section.css'
import Subject from './Subject'
function Section()
{
  return(
    <Subject/>
  )

  
}


export default Section;