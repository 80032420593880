import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment_List from "./Assignment_List";
import { message } from "antd";
class Assignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      no_of_questions: "",
      question_paper: "",
      answer_booklet: "",
      search: "",
      data: [],
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      AUTHORIZATION: `Token ${token}`,
    };

    axios
      .get(
        `${process.env.REACT_APP_API_URL}learning/api/teacher-test-series/`,
        { headers: headers }
      )
      .then((response) => {
        this.setState({ data: response.data.data });
      })
      .catch((err) => {
        message.destroy();
        message.error(err);
        console.log(err);
      });
  }

  // onFormSubmit = (event) => {
  //     event.preventDefault();
  //     // window.location.href = "/license/";
  //     const data = {
  //       title : this.state.title,
  //       description : this.state.description,
  //       video_url : this.state.video_url,
  //       content_pdf : this.state.content_pdf,
  //       image : this.state.image,
  //       is_downloadable:this.state.is_downloadable,
  //       Chapter : this.state.Chapter,
  //       //mobile : JSON.parse(this.state.mobile),

  //     }
  //     console.log(data)
  //     const token = localStorage.getItem('token')
  //     console.log(token)
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "AUTHORIZATION": `Token ${token}`

  //     }
  //     console.log(this.state)
  //     console.log(data)

  //     axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-section/`, data, {headers : headers})
  //     .then(response => {
  //       // if(response.data.token){
  //       //   window.location.reload();
  //       // }
  //         // }
  //         console.log(response)

  //     })
  //     .catch(err => {
  //       console.log(err)
  //     });

  // }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickHandler = (event) => {
    event.preventDefault();
    const data = new FormData();

    data.append("name", this.state.name);
    data.append(
      "question_paper",
      this.state.question_paper,
      this.state.question_paper.name
    );
    data.append("no_of_questions", this.state.no_of_questions);
    data.append(
      "answer_booklet",
      this.state.answer_booklet,
      this.state.answer_booklet.name
    );

    const token = localStorage.getItem("token");
    const headers = {
      // "Content-Type": "multipart/form-data",
      AUTHORIZATION: `Token ${token}`,
    };
    
    message.loading("Adding Assigment");
    axios
      .post(`${process.env.REACT_APP_API_URL}learning/api/test-series/`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log("Getting inside this response :: {}", response);
        if (response) {
          message.destroy();
          window.location.reload();
        }
        if (response.statusText === "OK") {
          window.location.reload();
        }
        // }
      })
      .catch((err) => {
        message.destroy();
        message.error(err);
        console.log(err);
      });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.files[0],
    });
  };

  SearchItem = (e) => {
    const searchvalue = e.target.value;
    this.setState({ search: searchvalue });
  };

  render() {
    let item = [];
    item = this.state.data.filter((innerdata) => {
      if (this.state.search == "") return innerdata;
      else if (
        innerdata.name.toLowerCase().startsWith(this.state.search.toLowerCase())
      ) {
        return innerdata;
      }
    });

    return (
      <div className="p-0">
        <div className="search-box py-2 px-2">
          <div className="row mx-0 px-0 my-0 py-0">
            <div className="col-lg-6 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1">
              {/* <Link className="text-white" to="/section/">Subjects</Link><span className="px-3 tags"></span><Link className="text-white" to={`/${this.props.match.params.id}`}>{this.props.match.params.id}</Link><span className="px-3"></span>Sub Sections */}
              Test Series
            </div>

            <div className="col-lg-1 col-md-1 col-sm-1 col-0">
              {/* <select className="form-control select-field w-100">
                      <option>Select Fields</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select> */}
            </div>

            <div className="col-lg-1 col-md-4 col-sm-4  col-6"></div>

            <div className="col-lg-3 col-md-4 col-6  col-sm-4">
              <div className="input-group md-form form-sm form-2 pl-0">
                <input
                  className="form-control my-0 py-1 lime-border"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={this.SearchItem}
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text lime lighten-2"
                    id="basic-text1"
                  >
                    <i className="fas fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div
                className="card-body "
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                <i className="fas fa-plus mr-2"></i>Add Test Series
              </div>
            </div>
          </div>
          {/* {this.state.data.map((data)=><Assignment_List key ={data.id} id={data.id} name={data.name} question_paper={data.question_paper} test_answer_key_pdf={data.test_answer_key_pdf} no_of_questions={data.no_of_questions} test_answer_key_text={data.test_answer_key_text}/>)} */}
          {item.map((data) => (
            <Assignment_List
              key={data.id}
              id={data.id}
              name={data.name}
              question_paper={data.question_paper}
              test_answer_key_pdf={data.test_answer_key_pdf}
              no_of_questions={data.no_of_questions}
              test_answer_key_text={data.test_answer_key_text}
            />
          ))}
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Add Test Series
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={(event) => this.onClickHandler(event)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="name" className="email">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          aria-describedby="name"
                          placeholder="Series Name"
                          required="required"
                          value={this.state.name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="no_of_questions" className="email">
                          No. Of Questions
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="no_of_questions"
                          id="no_of_questions"
                          aria-describedby="no_of_questions"
                          required="required"
                          value={this.state.no_of_questions}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="question_paper" className="email">
                          Question Paper
                        </label>
                        <input
                          type="file"
                          required="required"
                          name="question_paper"
                          onChange={this.onChangeHandler}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="answer_booklet" className="email">
                          Answer Booklet PDF{" "}
                        </label>
                        <input
                          type="file"
                          required="required"
                          name="answer_booklet"
                          onChange={this.onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <center>
                      {" "}
                      <button type="submit" className="btn btn-info ">
                        Save
                      </button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Assignment;
