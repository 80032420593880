import React, { Component, Fragment } from "react";
import axios from 'axios';
import "./settings.css";
let forcedLearningQuizStatus = false;
let forcedLearningModuleStatus = false;



class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // id: '',
      force_learning_quiz: false,
      force_learning_model: false,
      // force_learning_both: '',
      force_time_quiz: 0,
      force_time_model: 0,
      quizMessage:'',
      modelMessage:''
      // company: ''
    }
  }

  // changeSettings(token) {
  //   const headers = {
  //     "Content-type": "application/json",
  //     "AUTHORIZATION": `Token ${token}`
  //   };

  //   const data = {
  //     "force_time_model": 100000,
  //     "force_learning_model": true,
  //     "force_time_quiz": 100000,
  //     "force_learning_quiz": true
  //   }

  //   axios.post(`${process.env.REACT_APP_API_URL}companies/api/forcelearning/`,data, { headers: headers })
  //     .then(response => {
  //       console.log(response);
       
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       this.setState({ error: true });
  //     });
  // }
  
  componentDidMount(){
    this.handleGet()

  }

  handleForcedLearningQuiz = () => {
    // forcedLearningQuizStatus = !forcedLearningQuizStatus;
    // console.log('forcedLearningQuizStatus changed to:' + forcedLearningQuizStatus);
    // forcedLearningQuizStatus === true ? this.setState({ force_learning_quiz: true }) : this.setState({ force_learning_quiz: false });
    this.setState({ force_learning_quiz: !this.state.force_learning_quiz })
    console.log('force_learning_quiz state:' + this.state.force_learning_quiz);
  }

  handleForcedLearningModule = () => {
    // forcedLearningModuleStatus = !forcedLearningModuleStatus;
    // console.log('forcedLearningModuleStatus changed to:' + forcedLearningModuleStatus);
    this.setState({ force_learning_model: !this.state.force_learning_model })
    console.log('force_learning_model state:' + this.state.force_learning_model);
  }

  quizTimeHandler = term => {
    this.setState({ force_time_quiz: parseInt(term) });
    console.log('force_time_quiz state:' + this.state.force_time_quiz);
  }

  moduleTimeHandler = term => {
    this.setState({ force_time_model: parseInt(term) });
    console.log('force_time_model state:' + this.state.force_time_model);
  }

  handleGet = ()=>{
    const token = localStorage.getItem("token");
    console.log(token);

    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_URL}companies/api/forcelearning/`, { headers: headers })
      .then(response => {
        console.log(response.data)
        this.setState({
          // id: '',
          force_learning_quiz: response.data.force_learning_quiz,
          force_learning_model: response.data.force_learning_model,
          // force_learning_both: '',
          force_time_quiz: response.data.force_time_quiz,
          force_time_model: response.data.force_time_model,
          // company: '',
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleSave = (type) => {
    console.log(this.state);
    const token = localStorage.getItem("token");
    console.log(token);

    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    }

    if(type == 'quiz'){
      var data = {
        // id: this.state.id,
        force_learning_quiz: this.state.force_learning_quiz,
        force_time_quiz: this.state.force_time_quiz,
      }
    }
    else{
      var data = {
        force_time_model: this.state.force_time_model,
        force_learning_model: this.state.force_learning_model,
      }
    }

    axios.post(`${process.env.REACT_APP_API_URL}companies/api/forcelearning/`, data, { headers: headers })
      .then(response => {
        console.log(response)
        if(type=='quiz'){
          this.setState({
            quizMessage:'Success'
          })
        }
        else{
          this.setState({
            modelMessage:'Success'
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    
  }

  render() {
    return (
      <Fragment>
        <div className="search-box py-2 ">
          <div className="row mx-0 px-0 my-0 py-0">
            <div className="col-lg-3 col-md-3 col-sm-4 text-center text-lg-left text-md-left text-sm-left mt-2">
              Settings
            </div>
          </div>
        </div>
        <div className="row main-content">
          <div className="col">
            <div className="forced-learning-adjust">
              <h2>Forced Learning Quiz</h2>
              <div className="row">
                <div className="col-sm-5">
                  <p>Enable/Disable:</p>
                </div>
                <div className="col-sm-5 margin-top" >
                  <button
                    type="button"
                    // style={{"btn btn-toggle active":}}
                    className={this.state.force_learning_quiz?"btn btn-toggle active":"btn btn-toggle"}
                    data-toggle="button"
                    aria-pressed={this.state.force_learning_quiz?"true":"false"}
                    onClick={() => this.handleForcedLearningQuiz()}
                  >
                    <div className="handle"></div>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <p>Time (in seconds):</p>
                </div>
                <div className="col-sm-5" >
                  <input type="number" value={this.state.force_time_quiz} name="quantity" min="1" onChange={(e) => this.quizTimeHandler(e.target.value)} />
                </div>
              </div>
            </div>
            <h6 className="align-center">{this.state.quizMessage}</h6>
            <div className="settings-save">
              <button type="submit" onClick={() => this.handleSave('quiz')}>Save</button>
            </div>
            <div className="forced-learning-adjust">
              <h2>Forced Learning Module</h2>
              <div className="row">
                <div className="col-sm-5">
                  <p>Enable/Disable:</p>
                </div>
                <div className="col-sm-5 margin-top">
                  <button
                    type="button"
                    className={this.state.force_learning_model?"btn btn-toggle active":"btn btn-toggle"}
                    data-toggle="button"
                    aria-pressed={this.state.force_learning_model?"true":"false"}
                    onClick={() => this.handleForcedLearningModule()}
                  >
                    <div className="handle"></div>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <p>Time (in seconds):</p>
                </div>
                <div className="col-sm-5">
                  <input type="number" name="quantity" value={this.state.force_time_model} onChange={(e) => this.moduleTimeHandler(e.target.value)} />
                </div>
              </div>
            </div>
            <h6 className="align-center">{this.state.modelMessage}</h6>
            <div className="settings-save">
              <button type="submit" onClick={() => this.handleSave('model')}>Save</button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Settings;
