import React from 'react';
import axios from 'axios';
import Spinner from '../../../Component/spinner';
import HrList from './HrList';

class HR extends React.Component {

    state = {
        username: '',
        email : '',
        mobile : '',
        password : '',
        branches : [],
        active : false,
    }
  
  
    onFormSubmit = (event) => {
        event.preventDefault();
        // this.props.history.push("/committe/");
        window.location.href = "/hr/";
        const data = {
          username :this.state.username,
          email : this.state.email,
          mobile : this.state.mobile,
          password : this.state.password,
          branches : this.state.branches,
          active : this.state.active,
        }
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
          
          
        }
  
        axios.post(`${process.env.REACT_APP_API_URL}companies/api/hr/`, data, {headers : headers})
        .then(response => {
          console.log(response)
        })
        .catch(err => {
          console.log(err)
        });
        
    }
    onChange = e => {
      this.setState({[e.target.name]: e.target.value})
    }
  
    onCheckBoxChange = e => {
      this.setState({active:!this.state.active})
    }
  


    render() {
        const {username, email,mobile, password } = this.state;
        var login = <h3>Add HR</h3>
        return (
            <div className='p-0'>




            <div className="search-box py-2 px-2">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                    Hr Records 
                  </div>

                  <div className="col-lg-3 col-md-1 col-sm-1 col-0">
                    {/* <select className="form-control select-field w-100">
                      <option>Select Fields</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select> */}
                  </div> 

                  <div className="col-lg-3 col-md-4 col-sm-4  col-6">

                    <button type="button" className="btn search-btn w-100 p-0 m-0 btn-sm" data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Add Hr
                    </button>

                  </div>

                
                  <div className="col-lg-3 col-md-4 col-6  col-sm-4">
                    <div className="input-group md-form form-sm form-2 pl-0">
                      <input className="form-control my-0 py-1 lime-border" type="text" placeholder="Search" aria-label="Search"/>
                      <div className="input-group-append">
                        <span className="input-group-text lime lighten-2" id="basic-text1"><i className="fas fa-search"
                            aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>












{/* 
<div className="row search-nav">
                <div className="col-sm-4">
                    <p> HR Records</p>
                </div>
                <div className="col-sm-3 m-auto">

                    <button type="button" class="btn p-0 m-0 btn-sm pop-btn" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fas fa-plus mr-1"></i>Add HR
                    </button>

                </div>
                <div className="col-sm-4 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><i class="fas fa-search"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div> */}
 
 

      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Add HR</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            






    <div className="row">
        <div className="col m-auto p-3 form-div">
          {/* <div class="row">
          {this.props.error ? this.props.error.message : login}
            <div class="col cancel">
              <i class="fas fa-times pr-2"></i>Cancel
            </div>
          </div> */}


              
              {/* {this.props.loading ?  */}
          {/* //       <Spinner/>
          // : */}
            <form onSubmit={this.onFormSubmit}>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="usernamefield" className="email">Username</label>
                <input type="text" name='username' className="form-control"  id="usernamefield" aria-describedby="emailHelp" placeholder="Enter Username"
                required="required"
                value={username}
                onChange={this.onChange}/>
              </div>
              </div>
              </div>


          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="emailfield" className="email">Email</label>
                <input type="text" name='email' className="form-control" id="emailfield" aria-describedby="emailHelp" placeholder="Enter Email"
                required="required" data-error="Please enter a valid email."
                value={email}
                onChange={this.onChange}/>
              </div>
              </div>
              </div>

              <div className="row">
            <div className="col">

              <div className="form-group">
                <label htmlFor="mobile" className="email">Mobile</label>
                <input
                value={mobile} 
                onChange={this.onChange}
                type="number" name='mobile' id="mobile" className="form-control" placeholder="Enter Mobile" required="required"/>
              </div>
              </div>
                </div>


                <div className="row">
            <div className="col">

              <div className="form-group">
                <label htmlFor="password" className="email">Password</label>
                <input
                value={password} 
                onChange={this.onChange}
                type="password" name='password' id="password" className="form-control" placeholder="Enter Password"
                required="required" />
              </div>
              </div>
              </div>

              <div className="row">
            <div className="col">
                <span className="mt-4">Is Active ?</span> 
                <label className="switch">
                    <input type="checkbox" 
                    value={this.state.isheadOfficer} 
                    onChange={this.onCheckBoxChange}
                    />
                    <span className="slider round"></span>
                  </label>
            </div>
          </div>


              <div className="row">
                <div className="col">
                    <center> <button type="submit" className="btn">Save</button></center> 
                </div>
              </div>
          </form>
          </div>
       </div>

       </div>

    </div>
  </div>
</div>
       <HrList/>
      </div>
        )
        
    }
}

export default HR;