import React from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import Chip from '@material-ui/core/Chip';
import View_Notification from "./View_Notification"

class Notification extends React.Component {
  constructor(props){
  super(props);
    this.state = {
      topic:'',
      notice:'',
      data:[],
      search:'',
  }
  }
  componentDidMount(){
    const token = localStorage.getItem('token')
    console.log(token)
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }


    axios.get(`${process.env.REACT_APP_API_URL}license/api/add-notice/`, {headers : headers})
    .then(response => {
      this.setState({data:response.data.data})
    })
    .catch(err => {
      console.log(err)
    });


      }

  // onFormSubmit = (event) => {
  //     event.preventDefault();
  //     // window.location.href = "/license/";
  //     const data = {
  //       title : this.state.title,
  //       description : this.state.description,
  //       video_url : this.state.video_url,
  //       content_pdf : this.state.content_pdf,      
  //       image : this.state.image,
  //       is_downloadable:this.state.is_downloadable,
  //       Chapter : this.state.Chapter,
  //       //mobile : JSON.parse(this.state.mobile),      

  //     }
  //     console.log(data)
  //     const token = localStorage.getItem('token')
  //     console.log(token)
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "AUTHORIZATION": `Token ${token}`  
        
  //     }
  //     console.log(this.state)
  //     console.log(data)

  //     axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-section/`, data, {headers : headers})
  //     .then(response => {
  //       // if(response.data.token){
  //       //   window.location.reload();
  //       // }
  //         // }
  //         console.log(response)
       
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     });
      
  // }
  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
    console.log(this.state)
  }


  
 
onClickHandler = (e) => {
  e.preventDefault();
  

  let formData = new FormData();   

    formData.append('topic', this.state.topic);
    formData.append('notice', this.state.notice);


const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.post(`${process.env.REACT_APP_API_URL}license/api/add-notice/`, formData, {headers : headers})
.then(response => {
   if(response.statusText==='OK'){
     window.location.reload();
   }
    // }
 
})
.catch(err => {
  console.log(err)
});

}
  

courseChange = (e) => {
    let target = e.target
    let name = target.name
   
    //here
    let value = Array.from(target.selectedOptions, option => parseInt(option.value));
      this.setState({
        my_course: value
      });
      let names = Array.from(target.selectedOptions, option => {
  
     return ( option.text)
  
    });
      this.setState({
        course_show: names
      });
      
  }
  
testChange = (e) => {
    let target = e.target
    let name = target.name
   
    //here
    let value = Array.from(target.selectedOptions, option => parseInt(option.value));
      this.setState({
        my_series: value
      });
      let names = Array.from(target.selectedOptions, option => {
  
     return ( option.text)
  
    });
      this.setState({
        series_show: names
      });
      
  }



  SearchItem = e => {
    const searchvalue=e.target.value;
    this.setState({search:searchvalue})
  }
    

  render () {


    let item=[];
    item =this.state.data.filter((innerdata)=>{
      if(this.state.search == '')
          return innerdata
      else if(innerdata.topic.toLowerCase().startsWith(this.state.search.toLowerCase())) {
          return innerdata
      }
    });

    return (
      <div className="p-0">

<div className="search-box py-2 px-2">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col-lg-6 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                    Notifications
                  </div>
                

                  <div className="col-lg-1 col-md-1 col-sm-1 col-0">
                   
                  </div> 

                  <div className="col-lg-1 col-md-4 col-sm-4  col-6">

                  
                  </div>

                
                  <div className="col-lg-3 col-md-4 col-6  col-sm-4">
                    <div className="input-group md-form form-sm form-2 pl-0">
                      <input className="form-control my-0 py-1 lime-border" type="text" placeholder="Search" aria-label="Search" name="search" onChange={this.SearchItem}/>
                      <div className="input-group-append">
                        <span className="input-group-text lime lighten-2" id="basic-text1"><i className="fas fa-search"
                            aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

<div className="row">
<div className="col-lg-4">
              <div className="card">
  <div className="card-body " style={{cursor: "pointer"}}  data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Add Notification

  </div>
</div>


</div>

{/* {this.state.data.map(d=><View_Notification {...d}/>)} */}
{item.map(d=><View_Notification {...d}/>)}

</div>




<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Notification</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">





<form onSubmit={(event) => this.onClickHandler(event)}>

<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="topic" className="email">Topic</label>
            <input type="text" className="form-control" name="topic" id="topic" aria-describedby="topic" placeholder="Topic Name"
            required="required"
            value={this.state.topic}
            onChange={this.onChange}/>
          </div>
      </div>



    <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notice" className="email">Description</label>
            <textarea rows="5" cols="2" className="form-control" name="notice" id="notice" aria-describedby="notice"
            required="required" 
            value={this.state.notice}
            onChange={this.onChange}/>
          </div>
      </div>

   

        </div>

        
           {/* <p style={{    wordWrap: "break-word"}}> */}
        
          
     
   
        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>

{/* 
</form> */}


</form>



    </div>

</div>
</div>
</div>


      </div>
    )
  }
}



export default Notification;