import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import { Spin } from 'antd';
function Student_List(props)
{
  const [data,SetData] = useState([]);
  const [load,setLoad]=useState(false);

  useEffect(()=>
  {
    localStorage.removeItem("student_name");
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    const id = props.match.params.id;
  axios.get(`${process.env.REACT_APP_API_URL}learning/api/test-series-students/?test_series=${id}`, {headers : headers})
  .then(response => {
    SetData(response.data.data)
    setLoad(true);
  })
  .catch(err => {
    console.log(err)
  });
  
  }
 
,[])

return(
<>  
<main className=" mt-5">
<h1 className="text-center" style={{fontSize:"22px"}}>Assignment - {localStorage.getItem('assign_name')}</h1>
                <ul className="list-group list-group-horizontal">
                <Link to={`/assignment/${props.match.params.id}`}><li className="list-group-item " >Question</li></Link>
                <Link to={`/assignment/${props.match.params.id}/students`}><li className="list-group-item active">Results</li></Link>

</ul>
</main>

{!load&&<div className="container text-center"><Spin/></div>}
{load&&
<div className="mt-5 ml-5 text-center" style={{height:"50vh",overflow:"auto"}}>
   <table className="table">

     <thead>
       <tr>
     <td>Student Name</td>
     <td>Status</td>
     <td>Answer Submitted</td>
     <td>Answer Checked</td>
     <td>Student Score</td>

     <td>View Answers</td>
     </tr>
     </thead>
     <tbody>
    {data.map(d=>
    
     <tr>
     <td>{d.user.first_name}</td>
     <td>{d.is_opened===true?("Open"):("Not")}</td>
     <td>{d.is_finished===true?("Yes"):("No")}</td>
     <td>{d.is_checked===true?("Yes"):("No")}</td>
     <td>{d.user.total_marks}</td>

     <td>{d.is_finished===true&&!d.is_checked?(<Link to=
     {{
      pathname: `/assignment/${props.match.params.id}/${d.user.id}`,
      s_name: d.user.first_name
    }}>View Answer</Link>):(d.is_finished===true&&d.is_checked)?(<Link to={{
      pathname: `/assignment/${props.match.params.id}/${d.user.id}/view`,
      s_name: d.user.first_name
    }}>View Submission</Link>):("")}</td>
    </tr> )}
    </tbody>
   </table>


</div>
}
</>
  )

  
}


export default Student_List;