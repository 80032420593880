import React from 'react';
import axios from 'axios';
import BranchList from './BranchList';
import './BranchCreate.css';

class BranchCreate extends React.Component {
  state = {
      name: '',
      address : '',
      country : '',
      state : '',
      city : '',
      zip_code: '',
      Active : false,
      first_name : '',
      email : '',
      mobile : '',
  }


  onFormSubmit = (event) => {
      event.preventDefault()
      // this.props.history.push("/hr/");
      window.location.href = "/branch/";
 
      const data = {
        name :this.state.name,
        address : this.state.address,
        country : this.state.country,
        state : this.state.state,
        city:this.state.city,
        zip_code:this.state.zip_code,
        active : this.active,
        first_name : this.first_name,
        email : this.email,
        mobile : this.mobile,
      }
      const token = localStorage.getItem('token')
      console.log(token)
      const headers = {
        "Content-Type": "application/json",
        "AUTHORIZATION": `Token ${token}`  
        
      }

      axios.post(`${process.env.REACT_APP_API_URL}companies/api/branch/`, data, {headers : headers})
      .then(response => {
        console.log(response)
      })
      .catch(err => {
        console.log(err)
      });
      
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  onCheckBoxChange = e => {
    this.setState({active:!this.state.active})
  }

  render () {
    return (
      <div className="m-0 p-0 right-side">


   
    <div className="search-box py-2 px-2">
      <div className="row mx-0 px-0 my-0 py-0">

        <div className="col-lg-3 col-md-3 col-sm-4 text-center text-lg-left text-md-left text-sm-left mt-2"> 
          Branch Records 
        </div>
        
        <div className="col-lg-3 col-md-3 col-sm-4 col-6">
          {/* <select className="form-control select-field w-100">
            <option>Select Fields</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select> */}
        </div> 

        <div className="col-lg-3 col-md-3 col-sm-4  col-6">

          <button type="button" className="btn search-btn w-100 p-0 m-0 btn-sm" data-toggle="modal" data-target="#exampleModalCenter">
            <i className="fas fa-plus mr-2"></i>Add Branch
          </button>

        </div>

      
        <div className="col-lg-3 col-md-3 col-6 d-sm-none d-md-block d-none">
          {/* <div className="input-group md-form form-sm form-2 pl-0">
            <input className="form-control my-0 py-1 lime-border" type="text" placeholder="Search" aria-label="Search"/>
            <div className="input-group-append">
              <span className="input-group-text lime lighten-2" id="basic-text1"><i className="fas fa-search"
                  aria-hidden="true"></i></span>
            </div>
          </div> */}
        </div>

      </div>
    </div>
    
  



{/* 
        <div className="row search-nav">
                <div className="col-sm-4">
                    <p> Branch Records</p>
                </div>
                <div className="col-sm-3 m-auto">

                    <button type="button" className="btn p-0 m-0 btn-sm pop-btn" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fas fa-plus mr-1"></i>Add Branch
                    </button>

                </div>
                <div className="col-sm-4 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><i className="fas fa-search"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div> */}
        {/* **************************** */}
 

<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Branch</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        

{/* ************************* */}


    <div className="row p- m-0">

        <div className="col m-auto p-3 form-div">
          {/* <div className="row">                                                                                                                 
            <h3>Add Branch</h3>
            <div className="col cancel">

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i className="fas fa-times pr-2"></i>Cancel
        </button>
              
            </div>
          </div> */}

      <form onSubmit={(event) => this.onFormSubmit(event)}>

      <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="name" className="email">Branch Name</label>
                  <input type="text" className="form-control" name="name" id="name" aria-describedby="emailHelp" placeholder="Branch Name"
                  required="required"
                  value={this.state.name}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="city" className="email">Branch City</label>
                    <input type="text" className="form-control" name="city" id="city" aria-describedby="emailHelp" placeholder="City"
                    required="required"
                    value={this.state.city}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="state" className="email">Branch State</label>
                  <input type="state" className="form-control" name="state" id="state" aria-describedby="emailHelp" placeholder="State"
                  required="required"
                  value={this.state.state}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="zip_code" className="email">Branch Zip code</label>
                    <input type="text" className="form-control" name="zip_code" id="zip_code" aria-describedby="emailHelp" placeholder="Zip Code"
                    required="required"
                    value={this.state.zip_code}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="address" className="email">Address</label>
                  <input type="text" className="form-control" name="address" id="address" aria-describedby="emailHelp" placeholder="Address"
                  required="required"
                  value={this.state.address}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="country" className="email">Country</label>
                  <input type="text" className="form-control" name="country" id="country" aria-describedby="emailHelp" placeholder="Country"
                  required="required"
                  value={this.state.country}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="first_name" className="email">Contact Person Name</label>
                  <input type="text" className="form-control" name="first_name" id="first_name" aria-describedby="emailHelp" placeholder="Name"
                  required="required"
                  value={this.state.first_name}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="mobile" className="email">Mobile</label>
                  <input type="number" className="form-control" name="mobile" id="mobile" aria-describedby="emailHelp" placeholder="Mobile"
                  //  pattern="[0-9]{10}"
                  required="required"
                  value={this.state.mobile}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="email" className="email">Email</label>
                    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Email"
                    required="required" data-error="Please enter a valid email."
                    value={this.state.email}
                    onChange={this.onChange}/>
                    <div className="help-block with-errors"></div>
                  </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
                <span className="mt-4">Is Active ?</span> 
                <label className="switch">
                    <input type="checkbox" 
                    value={this.state.isheadOfficer} 
                    onChange={this.onCheckBoxChange}
                    />
                    <span className="slider round"></span>
                  </label>
            </div>
          </div>
        <div className="row">
              <div className="col">
                  <center> <button type="submit" name="submit" className="btn">Save</button></center> 
              </div>
        </div>
      </form>
      </div>
    </div >
       </div>

    </div>
  </div>
</div>

       <div className="branch mt-3">
       <BranchList />
       </div>
      </div>
    )
  }
}

export default BranchCreate;