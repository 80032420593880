import React from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import Plan_List from "./Plan_List"
import Chip from '@material-ui/core/Chip';

class Plans extends React.Component {
  constructor(props){
  super(props);
    this.state = {
      name : '',
      duration : '1 Month',
      price : '',
      course : [],
      course_show:[],
      my_course:[],
  
      series : [],
      series_show:[],
      my_series:[],
      data:[],
      search:'',
  }
  }
  componentDidMount(){
    const token = localStorage.getItem('token')
    console.log(token)
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }


    axios.get(`${process.env.REACT_APP_API_URL}subscription/api/subscription-plans/`, {headers : headers})
    .then(response => {
       this.setState({data:response.data.data})
    })
    .catch(err => {
      console.log(err)
    });


    

    axios.get(`${process.env.REACT_APP_API_URL}learning/api/teacher-test-series/`, {headers : headers})
    .then(response => {
       this.setState({series:response.data.data})
    })
    .catch(err => {
      console.log(err)
    });


    

    axios.get(`${process.env.REACT_APP_API_URL}learning/api/add-course/`, {headers : headers})
    .then(response => {
       this.setState({course:response.data.data})
    })
    .catch(err => {
      console.log(err)
    });
  }

  // onFormSubmit = (event) => {
  //     event.preventDefault();
  //     // window.location.href = "/license/";
  //     const data = {
  //       title : this.state.title,
  //       description : this.state.description,
  //       video_url : this.state.video_url,
  //       content_pdf : this.state.content_pdf,      
  //       image : this.state.image,
  //       is_downloadable:this.state.is_downloadable,
  //       Chapter : this.state.Chapter,
  //       //mobile : JSON.parse(this.state.mobile),      

  //     }
  //     console.log(data)
  //     const token = localStorage.getItem('token')
  //     console.log(token)
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "AUTHORIZATION": `Token ${token}`  
        
  //     }
  //     console.log(this.state)
  //     console.log(data)

  //     axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-section/`, data, {headers : headers})
  //     .then(response => {
  //       // if(response.data.token){
  //       //   window.location.reload();
  //       // }
  //         // }
  //         console.log(response)
       
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     });
      
  // }
  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
    console.log(this.state)
  }



SearchItem = e => {
  const searchvalue=e.target.value;
  this.setState({search:searchvalue})
}
  
 
onClickHandler = (e) => {
  e.preventDefault();
  const d ={
    name : this.state.name,
    duration : this.state.duration,
    price : this.state.price,
    course : this.state.my_course,
    test_series :this.state.my_series,
    
  }
 console.log(d)

const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.post(`${process.env.REACT_APP_API_URL}subscription/api/create-subscription-plan/`, d, {headers : headers})
.then(response => {
   if(response.statusText==='OK'){
     window.location.reload();
   }
    // }
 
})
.catch(err => {
  console.log(err)
});

}
  

courseChange = (e) => {
    let target = e.target
    let name = target.name
   
    //here
    let value = Array.from(target.selectedOptions, option => parseInt(option.value));
      this.setState({
        my_course: value
      });
      let names = Array.from(target.selectedOptions, option => {
  
     return ( option.text)
  
    });
      this.setState({
        course_show: names
      });
      
  }
  
testChange = (e) => {
    let target = e.target
    let name = target.name
   
    //here
    let value = Array.from(target.selectedOptions, option => parseInt(option.value));
      this.setState({
        my_series: value
      });
      let names = Array.from(target.selectedOptions, option => {
  
     return ( option.text)
  
    });
      this.setState({
        series_show: names
      });
      
  }



   

 


  render () {
    let item=[];
    item =this.state.data.filter((innerdata)=>{
      if(this.state.search == '')
          return innerdata
      else if(innerdata.name.toLowerCase().startsWith(this.state.search.toLowerCase())) {
          return innerdata
      }
    });
  






    return (
      <div className="p-0">

<div className="search-box py-2 px-2">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col-lg-6 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                  {/* <Link className="text-white" to="/section/">Subjects</Link><span className="px-3 tags"></span><Link className="text-white" to={`/${this.props.match.params.id}`}>{this.props.match.params.id}</Link><span className="px-3"></span>Sub Sections */}
              Subscription Plans
                  </div>
                

                  <div className="col-lg-1 col-md-1 col-sm-1 col-0">
                    {/* <select className="form-control select-field w-100">
                      <option>Select Fields</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select> */}
                  </div> 

                  <div className="col-lg-1 col-md-4 col-sm-4  col-6">

                  
                  </div>

                
                  <div className="col-lg-3 col-md-4 col-6  col-sm-4">
                    <div className="input-group md-form form-sm form-2 pl-0">
                      <input className="form-control my-0 py-1 lime-border" onChange={this.SearchItem} type="text" placeholder="Search" name="search" aria-label="Search"/>
                      <div className="input-group-append">
                        <span className="input-group-text lime lighten-2" id="basic-text1"><i className="fas fa-search"
                            aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
<div style={{height:"80vh",overflow:"auto"}}>
<div className="row">
<div className="col-lg-4">
              <div className="card">
  <div className="card-body " style={{cursor: "pointer"}}  data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Add Plan

  </div>
</div>
</div>
{/* {this.state.data.map((data)=><Plan_List key ={data.id} {...data}courses={this.state.course} series={this.state.series}/>)} */}
{item.map((data)=><Plan_List key ={data.id} {...data}courses={this.state.course} series={this.state.series}/>)}

</div>

</div>


<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Subscription Plan</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">





<form onSubmit={(event) => this.onClickHandler(event)}>
<p className="alert alert-danger">Note : Test-series cannot be removed from the plan once added</p>

<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="name" className="email">Name</label>
            <input type="text" className="form-control" name="name" id="name" aria-describedby="name" placeholder="Plan Name"
            required="required"
            value={this.state.name}
            onChange={this.onChange}/>
          </div>
      </div>



    <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="price" className="email">Price</label>
            <input type="number" className="form-control" name="price" id="price" aria-describedby="price"
            required="required"
            value={this.price}
            onChange={this.onChange}/>
          </div>
      </div>

    <div className="col-md-6">
          <div className="form-group">
          <label htmlFor="duration" className="email">Duration</label>
            <select id="duration" name="duration" className="form-control"  required="required"
            value={this.state.duration}
            onChange={this.onChange}>
              <option name='duration' value="1 Month">1 Month</option>
              <option name='duration' value="3 Months">3 Months</option>
              <option name='duration' value="6 Months">6 Months</option>
              <option name='duration' value="9 Months">9 Months</option>
              <option name='duration' value="12 Months">1 Year</option>
              <option name='duration' value="2 Years">2 Years</option>
              <option name='duration' value="3 Years">3 Years</option>


            </select>


            {/* <label htmlFor="duration" className="email">Duration</label>
            <input type="text" className="form-control" name="duration" id="duration" aria-describedby="duration"
            required="required"
            value={this.state.duration}
            onChange={this.onChange}/> */}
          </div>
      </div>


    <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="course" className="email">course</label>
           

          <select style={{width:"100%"}} id="course" name="course" multiple={true} onChange={this.courseChange}  value={this.state.selectOptions} >
          {this.state.course.map(data=><option name={data.course_name} value={data.id}>{data.course_name}</option>)}

        </select>


          </div>
      </div>

      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="showcourses" className="email">Courses</label>
            {this.state.course_show.map((name)=><Chip label={name}/>)}

          </div>
      </div>

      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="series" className="email">test series</label>
           

          <select style={{width:"100%"}} id="series" name="series" multiple={true} onChange={this.testChange}  value={this.state.selectOptions} >
          {this.state.series.map(data=><option name={data.name} value={data.id}>{data.name}</option>)}

        </select>


          </div>
      </div>

      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="showseries" className="email">Test Series</label>
            {this.state.series_show.map((name)=><Chip label={name}/>)}

          </div>
      </div>

        </div>

        
           {/* <p style={{    wordWrap: "break-word"}}> */}
        
          
     
   
        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>

{/* 
</form> */}


</form>



    </div>

</div>
</div>
</div>


      </div>
    )
  }
}



export default Plans;