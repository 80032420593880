import React from 'react';
import Table from '../../../Component/licensetable';
import axios from 'axios';


class LicenseList extends React.Component {
    state = {
        licenses : [],
        error : false
    }

    componentDidMount() {
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        axios.get( `${process.env.REACT_APP_API_URL}license/api/license/`, {headers:headers} )
        .then( response => {
            this.setState({licenses:response.data.license_list});
            console.log( response.data.license_list );
        } )
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }

    render() {
        let license = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            license = this.state.licenses.map(li => {
                return <Table
                    key={li.UUID} 
                    name={li.company}
                    license={li.license}
                    issued={li.username}
                    status={li.active_license}
                    
                    />
            });





        }


        return (
            <div className="row main-content">

            <div className="col">
            {/* <table className="row-space text-left pd-5"> */}
            <table className="table table-striped">
            <thead>
                <tr> 
                    {/* <th scope="col">Branch Id</th> */}
                    <th scope="col">Company Name</th>
                    <th scope="col">License no</th>
                    <th scope="col">Issued To</th>
                    <th scope="col">License Status</th>
                    

                   
                   
                    
                </tr>
            </thead>
           
                {console.log(license)}
                {license}
            </table>
            </div>
            </div>



        )
    }
}

export default LicenseList;