import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import Chip from '@material-ui/core/Chip';
import {Button} from "antd"
import {Spin} from "antd"
function Plan_List(props)
{
  const [data,SetData] = useState({...props
  });
  const [mycourse,SetCourse] = useState();
  const [myseries,SetSeries] = useState([]);
  const [showcourse,SetShowCourse] = useState([]);
  const [showseries,SetShowSeries] = useState([]);
  const [open,setOpen] = useState(false)
  const [loaded_plans,setLoading_Plans] = useState(true)

  const [files,setFiles] = useState({
    question_paper:'',
    test_answer_key_pdf:'',
  });


  useEffect(()=>
  {
console.log(" i am updated FILES",files.question_paper)
console.log(" i am updated Answer",files.test_answer_key_pdf)

  },[files])


//get plans
  useEffect(()=>
  {
  
      const token = localStorage.getItem('token')
      const headers = {
        // "Content-Type": "multipart/form-data",
        "AUTHORIZATION": `Token ${token}`  
        
      }
      axios.get(`${process.env.REACT_APP_API_URL}subscription/api/create-subscription-plan/?id=${props.id}`, {headers : headers})
      .then(response => {
          console.log("GETTING PLAN DATA",response.data)
          // SetData({...data,plans:response.data.subscription})
          const c_names=response.data.test_series.map(d=>({name:d.name,id:d.id}))

          SetShowCourse(response.data.data)
          SetShowSeries(c_names)
          // }
       
      })
      .catch(err => {
        console.log(err)
      });
  
  },[open])
     



//delete course
  const showName=(e)=>{


    const id = e.currentTarget.dataset.id
    const token = localStorage.getItem('token')
    const headers = {
      // "Content-Type": "multipart/form-data",
      "AUTHORIZATION": `Token ${token}`  
    }
    console.log(id)
    axios.delete(`${process.env.REACT_APP_API_URL}subscription/api/update-subscription-plan/?id=${props.id}&course_id=${id}`, {headers : headers})
    .then(response => {
      console.log(response)
      setOpen(!open)
        // if(response.statusText==='OK'){
        //   window.location.reload();
        // }
        // }
     console.log(response)
    })
    .catch(err => {
      console.log(err)
    });
    
  }



//delete plan
  const deleteMe=()=>
  {
   
  const id = props.id
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  console.log(id)
  axios.delete(`${process.env.REACT_APP_API_URL}subscription/api/create-subscription-plan/?id=${id}`, {headers : headers})
  .then(response => {
      if(response.statusText==='OK'){
        window.location.reload();
      }
      console.log(response)
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  }

  const callMe=(e)=>
  {

    SetData({...props
    })
    // const x=props.course.map(d=>d.course_name)
    // SetShowCourse(x)
    // const y=props.course.map(d=>d.id)
    // SetCourse(y)
    // const z=props.test_series.map(d=>d.name)
    // SetShowSeries(z)
    // const a=props.test_series.map(d=>d.id)
    // SetSeries(a)
    setOpen(!open)

  }

  const changed=(e)=>
  {
    SetData({...data,[e.target.name]:e.target.value});
    console.log(data)

  }

  
const onChangeHandler=event=>{

    SetData({...data,
      [event.target.name]: event.target.files[0],
    })
      setFiles({...files,[event.target.name]:event.target.files[0].name})

  
    
  
    console.log("FILES questiomn",files.question_paper)
    console.log("FILES Answer",files.test_answer_key_pdf)

    console.log("Data",data.question_paper)

  
  
  }
   


 
  const onClickHandler = () => {
    window.location.reload();
  }
  //   const d ={
  //     name : data.name,
  //     duration : data.duration,
  //     price : data.price,
  //     course : mycourse,
      
  //   }
  //  console.log(d)
  
  // const token = localStorage.getItem('token')
  // const headers = {
  //   // "Content-Type": "multipart/form-data",
  //   "AUTHORIZATION": `Token ${token}`  
    
  // }
  // axios.post(`${process.env.REACT_APP_API_URL}subscription/api/update-subscription-plan/?id=${props.id}`, d, {headers : headers})
  // .then(response => {
  //    if(response.statusText==='OK'){
  //     //  window.location.reload();
  //     setOpen(!open)
  //    }
  //     // }
   
  // })
  // .catch(err => {
  //   console.log(err)
  // });
  

    

  
// const onClickHandler = () => {
  // const inputs = new FormData() 
  
  // inputs.append('name',  data.name)
  // inputs.append('no_of_questions',  data.no_of_questions)
//   inputs.append('test_answer_key_pdf',  data.test_answer_key_pdf)
//   if((typeof(data.question_paper))==="object") 
//   inputs.append('question_paper', data.question_paper,data.question_paper.name)
//   if((typeof(data.test_answer_key_pdf))==="object") 
//   inputs.append('test_answer_key_pdf', data.test_answer_key_pdf,data.test_answer_key_pdf.name)
 
//   console.log(inputs)
// console.log("Did we get updated",data)
// console.log("SUBMIT files" ,files)
// const id = props.id
// const token = localStorage.getItem('token')
// const headers = {
//   // "Content-Type": "multipart/form-data",
//   "AUTHORIZATION": `Token ${token}`  
  
// }
// console.log(inputs)
// console.log(id)
// axios.put(`${process.env.REACT_APP_API_URL}learning/api/test-series/?id=${id}`, inputs, {headers : headers})
// .then(response => {
//     if(response.statusText==='OK'){
//       window.location.reload();
//     }
//     // }
//  console.log(response)
// })
// .catch(err => {
//   console.log(err)
// });

// }



const courseChange = (e) => {
  let target = e.target
  let name = target.name
 
  //here
  let value = Array.from(target.selectedOptions, option => parseInt(option.value));
    let names = Array.from(target.selectedOptions, option => {

   return ( option.text)

  });
  console.log(e.target.name)
  console.log(e.target.value)
  if(e.target.value!=""){
    const d ={
      name : data.name,
      duration : data.duration,
      price : data.price,
      course :value,
      
    }
   console.log(d)
  
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  axios.post(`${process.env.REACT_APP_API_URL}subscription/api/update-subscription-plan/?id=${props.id}`, d, {headers : headers})
  .then(response => {
     if(response.statusText==='OK'){
      //  window.location.reload();
      setOpen(!open)
     }
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  




  }
}


// const courseChange = (e) => {
//   let target = e.target
//   let name = target.name
 
//   //here
//   let value = Array.from(target.selectedOptions, option => parseInt(option.value));
//     let names = Array.from(target.selectedOptions, option => {

//    return ( option.text)

//   });
//   console.log(e.target.name)
//   console.log(e.target.value)
//   if(e.target.value!=""){
//     SetCourse([...mycourse,target.value]);

//   let x = showcourse
//   x.push(names[0])
// console.log("Testing",x)
//   // SetShowCourse([...showcourse,names]);
//   SetShowCourse(x);
//   }
// }

const plantoall = ()=>{
console.log(props.id)
setLoading_Plans(false)
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.post(`${process.env.REACT_APP_API_URL}license/api/giving-students-plan/?plan=${props.id}`, null, {headers : headers})
.then(response => {
   if(response.statusText==='OK'){
    //  window.location.reload();
    // setOpen(!open)
    setLoading_Plans(true)
    console.log(response)
   }

   console.log(response)
    // }
 
})
.catch(err => {
  console.log(err)
  setLoading_Plans(true)

});



}


// const testChange = (e) => {
//   let target = e.target
//   let name = target.name
 
//   //here
//   let value = Array.from(target.selectedOptions, option => parseInt(option.value));
//     SetSeries(value);
//     let names = Array.from(target.selectedOptions, option => {

//    return ( option.text)

//   });
//    SetShowSeries(names);
    
// }


//test
const testChange = (e) => {
  let target = e.target
  let name = target.name
 
  //here
  let value = Array.from(target.selectedOptions, option => parseInt(option.value));
    let names = Array.from(target.selectedOptions, option => {

   return ( option.text)

  });
  console.log(e.target.name)
  console.log(e.target.value)
  if(e.target.value!=""){
    const d ={
      test_series :value[0],
      
    }
   console.log(d)
  
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  axios.put(`${process.env.REACT_APP_API_URL}subscription/api/update-subscription-plan/?id=${props.id}`, d, {headers : headers})
  .then(response => {
     if(response.statusText==='OK'){
      //  window.location.reload();
      setOpen(!open)
     }
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  
  }
}


//assignment delete

const assignmentDelete=(e)=>{

console.log(e.currentTarget.dataset.id)
  // const id = e.currentTarget.dataset.id
  // const token = localStorage.getItem('token')
  // const headers = {
  //   // "Content-Type": "multipart/form-data",
  //   "AUTHORIZATION": `Token ${token}`  
  // }
  // console.log(id)
  // axios.delete(`${process.env.REACT_APP_API_URL}subscription/api/update-subscription-plan/?id=${props.id}&course_id=${id}`, {headers : headers})
  // .then(response => {
  //   console.log(response)
  //   setOpen(!open)
  //     // if(response.statusText==='OK'){
  //     //   window.location.reload();
  //     // }
  //     // }
  //  console.log(response)
  // })
  // .catch(err => {
  //   console.log(err)
  // });
  
}



return(
<>  



<div className="modal fade" id={`test${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`View ${props.name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
        
    <div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="name" className="email">Plan Name: {data.name}</label>
          </div>
      </div>
       <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="duration" className="email">Duration: {data.duration}</label>
          
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="price" className="email">Price: {data.price} </label>
          
      </div>
</div>


<div className="col-md-12">
        
          <div className="form-group">
            <label htmlFor="showcourses" className="email">Courses</label>
           {data.course.map(d=><Chip label={d.course_name}/>)}
          
      </div>
</div>


<div className="col-md-12">
<div className="form-group">
            <label htmlFor="showtestseries" className="email">Test Series</label>
           {data.test_series.map(d=><Chip label={d.name}/>)}
          
      </div>
</div>


<div className="col-md-12">
  {!loaded_plans&&<Spin/>}
<div className="form-group">
<label htmlFor="toall" className="email">Provide the plan to all students?</label>

           <Button onClick={plantoall}>Yes</Button>
          
      </div>
</div>
     
      </div>
</div></div>
</div>
</div>




{ /*edit */ }

<div className="modal fade" id={`edit${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`Edit ${props.name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
        
    <div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="name" className="email">Plan Name: {data.name}</label>
          </div>
      </div>

       <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="duration" className="email">Duration: {data.duration}</label>
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="price" className="email">Price: {data.price} </label>
          
      </div>
</div>
 <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="course" className="email">course</label>
           

          <select style={{width:"100%"}} id="course" name="course" onChange={courseChange}    >
            <option value="" disabled selected >Select course</option>
          {props.courses.map(data=><option name={data.course_name} value={data.id}>{data.course_name}</option>)}

        </select>


          </div>
      </div>



      {/* <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="series" className="email">test series</label>
           

          <select style={{width:"100%"}} id="series" name="series" onChange={testChange} multiple={true}  >
          {props.series.map(data=><option name={data.name} value={data.id}>{data.name}</option>)}

        </select>


          </div>
      </div>
 */}




<div className="col-md-12">
          <div >
         Courses: {showcourse.map(d=><Chip label={d.course_name} data-id={d.id} onClick={showName}/>)}
          
      </div>
</div>



{/* test series */}

<div className="col-md-12">
          <div className="form-group">
            <label htmlFor="course" className="email">test series</label>
          <select style={{width:"100%"}} id="course" name="course" onChange={testChange} >
            <option value="" disabled selected >Select testseries</option>
          {props.series.map(data=><option name={data.name} value={data.id}>{data.name}</option>)}

        </select>


          </div>
      </div>


<div className="col-md-12">
          <div >
         Test Series: {showseries.map(d=><Chip label={d.name} data-id={d.id} onClick={assignmentDelete} />)}
          
      </div>
</div>

{/* 
<div className="col-md-12">
          <div >
           <ul  className="list-inline">Test Series: 
           {data.test_series.map(d=><li>{d.name}</li>)}</ul>

          

      </div> 
</div> */}
<div className="col">
            <center> <button type="submit" className="btn btn-info "  onClick={onClickHandler}>Save</button></center> 
        </div>
      </div>
</div></div>
</div>
</div>

{ /*edit */ }


<div className="col-lg-4">

              <div className="card">

  <div className="card-body">

{props.name}<span className="float-right" onClick={callMe} style={{cursor:"pointer"}} name={props.name}  data-toggle="modal" data-target={`#test${props.id}`} >  <i className="fa fa-eye" aria-hidden="true"></i>
 </span> <span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  onClick={deleteMe}> <i  className="fa fa-trash" aria-hidden="true"></i> 

</span>
<span className="float-right" onClick={callMe} style={{cursor:"pointer",marginRight:"5px"}} name={props.name}  data-toggle="modal" data-target={`#edit${props.id}`} >  <i  className="fa fa-cog fa-lg" aria-hidden="true"></i> 

</span>
</div>

</div>
</div>
</>
  )

  
}


export default Plan_List;