import React from 'react';

const committeetable=(props)=>(
    <tbody>
                <tr className="solid">
                    <th scope="row">{props.sno}</th>
                    <td>{props.name}</td>
                    <td>{props.company}</td>
                    <td>{props.mobile}</td>
                    <td>{props.email}</td>
                    
                </tr>
         </tbody>

)
export default committeetable;