import React, { Component } from 'react';
import Table from '../../../Component/Complaintable';
import axios from 'axios';
import {Link} from 'react-router-dom';

class Complaint extends React.Component {
    state = {
        complain_list : [],
        error : false
    }

    componentDidMount() {
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        axios.get( `${process.env.REACT_APP_API_URL}complaints/api/complain/`, {headers:headers} )
        // axios.get( 'http://127.0.0.1:8000/complaints/api/complain/', {headers:headers} )
        .then( response => {
            this.setState({complain_list:response.data});
            console.log( response.data );
        })
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }

    render() {
        let complain_list = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            complain_list = this.state.complain_list.map(complaint => {
                return <Table
                    key={complaint.complain_id} 
                    sno={complaint.complain_id}
                    name={complaint.complaint_by}
                    repeat_times={complaint.repeat_times}
                    severity={complaint.severity}
                    accussed_name={complaint.accussed_name}
                    date={complaint.created_date}
                    status={complaint.status}
                    
                    />
            });
        }

        return (<div>
            <div className="search-box py-2 ">
                    <div className="row mx-0 px-0 my-0 py-0">

                        <div className="col-lg-3 col-md-3 col-sm-4 text-center text-lg-left text-md-left text-sm-left mt-2"> 
                        Branch Records 
                        </div>
                    </div>
                    </div>
                    
            <div className="row main-content">
                
            <div className="col">
            <table className="row-space">
            <thead>
                <tr className="rounded">
                    <th scope="col">S.No</th>
                    <th scope="col">Complainant</th>
                    <th scope="col">Times Repeated</th>
                    <th scope="col">Severity</th>
                    <th scope="col">Accused</th>
                    <th scope="col">Complaint Date</th>
                    <th scope="col">Complaint Status</th>
                </tr>
                </thead>
                {complain_list}
            </table>
            </div>
            </div>
            </div>
        )
    }
}


export default Complaint
