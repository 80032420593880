import React,{useState,useCallback} from 'react';
import axios from 'axios';
import LicenseList from './LicenseList';
import '../Branch/BranchCreate.css';
import Subject from './Subject'
import {Link} from "react-router-dom"
import Spinner from '../../../Component/spinner';
import {message} from "antd";
// const style = {
//     position : 'absolute',
//     top : '50%',
//     left : '50%',
//     transform : 'translate(-50%, -50%)',
//     z-index: '9999',

    
// }
function SubSection_List(props)
{
const [load,setLoad]=useState(false);
const [data,SetData] = useState(null);
const [feed,setFeed] = useState({ 
title : '',
description : '',
video_url : '',
content_pdf : null,  
image : null,
is_downloadable : "False",
Chapter : '',
data:[]
});
const [files,setFiles] = useState({
  image:'',
  content_pdf:'',
});
const deleteMe=()=>
{
 
const id = props.id
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
console.log(data)
console.log(id)
axios.delete(`${process.env.REACT_APP_API_URL}learning/api/add-section/?id=${id}`, {headers : headers})
.then(response => {
    if(response.statusText==='OK'){
      window.location.reload();
    }
    console.log(response)
    // }
 
})
.catch(err => {
  console.log(err)
});
}
  const callMe=(e)=>
  {
    const pdfs =props.content_pdf!="N/A"?props.content_pdf.substring(props.content_pdf.lastIndexOf('/')+1):("N/A");
    const imgs =props.image!="N/A"?props.image.substring(props.image.lastIndexOf('/')+1):("N/A");

    setFeed({
      title : props.title,
description : props.description,
video_url : props.video_url,
content_pdf : props.content_pdf,  
image : props.image,
Chapter : props.Chapter,
    });
    setFiles({
      content_pdf : pdfs,  
      image : imgs,
    })

  }
  
const onChangeHandler=event=>{
if(event.target.files[0]!=undefined){
  setFeed({...feed,
    [event.target.name]: event.target.files[0],
  })
    console.log(event.target.files[0].name)
    setFiles({...files,[event.target.name]:event.target.files[0].name})
}
}




const onChange = e => {
    setFeed({...feed,[e.target.name]: e.target.value})
  }
  
const onClickHandler = (event) => {
  event.preventDefault()
  setLoad(true)
 const data = new FormData()
  data.append('title',  feed.title)
  data.append('description', feed.description)
  if(feed.video_url!='N/A')data.append('video_url', feed.video_url)
  if(feed.video_url=='N/A')data.append('video_url', "")


  // data.append('image', feed.image,feed.image.name)
  data.append('is_downloadable', "False")
  data.append('Chapter',feed.Chapter)
  if((typeof(feed.content_pdf))==="object") 
  data.append('content_pdf', feed.content_pdf,feed.content_pdf.name)
  if((typeof(feed.image))==="object") 
  data.append('image', feed.image,feed.image.name)
 
  console.log("This is the feed",feed)

const id = props.id
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
console.log(data)
console.log(id)
axios.put(`${process.env.REACT_APP_API_URL}learning/api/add-section/?id=${id}`, data, {headers : headers})
.then(response => {
    if(response.statusText==='OK'){
      window.location.reload();
    }
    console.log(response)
    // }
 
})
.catch(err => {
  console.log(err)
  setLoad(false)
  message.warning("Some error occured")
});

}



  return(
  <>  
 
    {console.log("Chapter list props",props.chap)}

<div className="modal fade" id={`t${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
  <h5 className="modal-title" id="exampleModalLongTitle">{`Edit ${props.title}`}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      {load?
  (<div className="loading">
  <Spinner/>
  </div>):("")
}
<form onSubmit={onClickHandler}>
      <div className="row">
        <div className="col m-auto p-3 form-div">
      <div className="row">
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="title" className="email">Title</label>
            <input type="text" className="form-control" name="title" id="title" aria-describedby="title" placeholder="Title"
            required
            value={feed.title}
            onChange={onChange}/>
          </div>
      </div>



    <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="description" className="email">Description</label>
            <input type="text" className="form-control" name="description" id="description" aria-describedby="description" placeholder="Description"
            required="required"
            value={feed.description}
            onChange={onChange}/>
          </div>
      </div>
    </div>


    <div className="row">

    <div className="col-md-12">
          <div className="form-group">
              <label htmlFor="video_url" className="email">Video Link</label>
              <input type="text" className="form-control" name="video_url" id="video_url" aria-describedby="video_url" placeholder="video url" required="required"
               
              value={feed.video_url}
              onChange={onChange}/>
            </div>
      </div>

</div>
<div class="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="content_pdf"  className="email">PDF: {files.content_pdf}</label>
            <input type="file" id ="content_pdf"  name="content_pdf" onChange={onChangeHandler}/>
         
          </div>
      </div>

      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="image" className="email"> Image: {files.image}</label>
            <input type="file"  id ="image" name="image" onChange={onChangeHandler}/>
         
          </div>
      </div>
    </div>



        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>

        

</div>
</div>
</form>

</div>
</div>
</div>
</div>



<div className="col-lg-4">


  <div className="card">
  <div className="card-body">
  {props.title}<span  className="float-right" style={{cursor:"pointer"}} name={props.title}  data-toggle="modal" data-target={`#t${props.id}`} onClick={callMe}>  <i  className="fa fa-cog fa-lg" aria-hidden="true"></i>

</span><span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  onClick={deleteMe}> <i  className="fa fa-trash" aria-hidden="true"></i> 

</span>
  </div>
  {/* <div className="card-footer">
  <button type="button" name={props.name} className="btn-primary btn-sm"  data-toggle="modal" data-target={`#${props.id}`} onClick={callMe}>Edit</button>

  </div> */}
</div>
</div>
</>
  )

  
}

export default SubSection_List;