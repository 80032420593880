import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';
import { Link } from 'react-router-dom';
import Spinner from '../../../Component/spinner';
import './Signup.css';


class Signup extends React.Component {

    state = {username: "",
            email:"",
            mobile: "",
            password:""}

    onFormSubmit = (event) => {
        event.preventDefault();
        
        this.props.onAuth(this.state.username,this.state.email, this.state.mobile, this.state.password, this.props)
        
        
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    render() {
        const {username, email,mobile, password } = this.state;
    var login = <h1 className="heading"><center>Sign Up</center></h1>
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 col-md-3 m-auto">
            {login}
              {this.props.error ? 'Invalid username or password' : ""}
              {this.props.loading ? 
                <Spinner/>
          :
            <form onSubmit={this.onFormSubmit}>
              <div className="form-group">
                <label htmlFor="usernamefield" className="email">Username</label>
                <input type="text" name='username' className="form-control"  id="usernamefield" aria-describedby="emailHelp" placeholder="Username"
                required="required"
                value={username}
                onChange={this.onChange}/>
              </div>

              <div className="form-group">
                <label htmlFor="emailfield" className="email">Email</label>
                <input type="text" name='email' className="form-control" id="emailfield" aria-describedby="emailHelp" placeholder="Email"
                 data-error="Please enter a valid email." required="required"
                value={email}
                onChange={this.onChange}/>
              </div>

              <div className="form-group">
                <label htmlFor="mobile" className="email">Mobile</label>
                <input type="number" name='mobile' id="mobile" className="form-control" placeholder="Mobile" required="required"
                value={mobile} 
                onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="email">Password</label>
                <div className="row">
                    <div className="col-sm-9"><input 
                        type="password" name='password' className="form-control" 
                        id="exampleInputPassword1" placeholder="*********" required="required"
                        value={password} 
                        onChange={this.onChange}
                        /></div>
                    <div className="col-sm-2"><i className="far fa-eye-slash"></i></div>
                </div> 
              </div>


              {/* <div className="row  remember-text">
                    <div className="col-sm-6">
                        <div className="checkbox">
                            <label><input type="checkbox"/> Remember Password</label>
                        </div>
                    </div>
                    <div className="col-sm-6 text-right">
                    <Link to="/login/"> Forgot Password</Link> 
                    </div>
             </div> */}
              <button type="submit" className="btn btn-block">Signup</button>
          </form>}
          <p className="sign-up">Already have an account? <Link to="/login/">Login</Link></p>
            </div>
          </div>
        </div>
        )
        
    }
}

const mapStateToProps = (state) => {
  return {
      loading: state.loading,
      error: state.error
  }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, email,  mobile, password, props) => dispatch(actions.authSignup(username, email, mobile, password, props)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);