import React from 'react';


class Landing extends React.Component {

    componentDidMount (){
        
        
    }

    render() {
        return (
            <div>
            <h1>Landing</h1>
            </div>
        )

    }
}

export default Landing;