import React from 'react';
import { Switch, Route, Link,NavLink  } from 'react-router-dom';
import Wrapper from './Container/HOC/Wrapper';

import Landing from './Landing';
import Login from './Container/View/Login/Login';
import Logout from './Container/View/Logout/Logout';
import ThankyouLogout from './Container/View/ThankyouLogout/ThankyouLogout';
import Signup from './Container/View/Signup/Signup';
import Company from './Container/View/Company/CompanyCreate';
import Home from './Container/View/Home/Home';
import Branch from './Container/View/Branch/BranchCreate';
import HR from './Container/View/Hr/Hr';
import CompanyCommitte from './Container/View/CompanyCommittee/CompanyCommitte';
import License from './Container/View/License/License';
import './style.css';
import logo from './logo.png';

import { connect } from 'react-redux';
import * as actions from './store/actions/auth';



// import arrow from './arrow.png';
import search from './Search.png'
import ComplainDetail from './Container/View/ComplainDetail/ComplainDetail';
import Complain from './Container/View/ComplainDetail/Complain';
import Complaint from './Container/View/Complaints/Complaint';
import Settings from './Container/View/Settings/Settings';
import Section from './Container/View/Section/Section';
import Chapter from './Container/View/Section/Chapter';
import SubSection from './Container/View/Section/SubSection';
import Assignment from './Container/View/Assignment/Assignment';
import Students from './Container/View/Assignment/Student';
import Student_List from './Container/View/Assignment/Student_List';
import Student_Answer from './Container/View/Assignment/Student_Answer';
import View_Submission from './Container/View/Assignment/View_Submission';
import Plans from './Container/View/Plans/Plans';
import Notification from './Container/View/Notification/Notification';


class Navigation extends React.Component {
    state = { showModal: false };
    
    hideModal = () => {
        this.setState({ showModal: true});
    };

    onModalChange = e => {
        this.setState({showModal: !this.state.showModal})
      }

    render () {
        return (
            <div>
                {this.props.isAuthenticated ?
                
            <React.Fragment>


    {this.props.logo_check&&<nav className="navbar navbar-expand-lg main-navbar ">
                <div className="logo"> {localStorage.getItem('logo')&&<img src={localStorage.getItem('logo')} className="myimg ml-5" alt="logo"/>}</div>

                   
					<div className="col-md-9 col-7">

					</div>
			
					<div className="dashboard-nav col-1 smallbar">
		
					
					<div className="dropdown">
                      <button className="dropbtn fas fa-ellipsis-v i-tag nav-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        
                      </button> 
					  
					  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
					   <ul>
                       <div className="dashboard-nav">
                        <Link  to="/home/">
                        Home          
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link  to="/branch/">        
                         Branch    
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link  to="/license/">                        
                        License          
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link  to="/section/">
                        Section          
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link  to="/assignment/">
                        Assignment          
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link to="/plans/">
                        Plans          
                        </Link> 
                        </div>
                        <div className="dashboard-nav">
                        <Link to="/logout/">
                        Logout          
                        </Link> 
                        </div>
					 <br></br>
					   </ul>
                      </div>
					   
                       </div> 

			 </div>
					
			  </nav>}
			 








    
                        
    <div className="row mt-5">
        {/* Side Menu  */}
        <div className="col-md-3 side-menu mt-5" id="side_bar">
            {/* Side Menu For desktop  */}

            <NavLink activeClassName="selected_route" exact to="/home/">
                
            <div  className="menu">  Home</div>
               
            </NavLink >
          
            <NavLink  activeClassName="selected_route" exact to="/branch/">
                
            <div  className="menu">      Branch
            </div>
            </NavLink > 
            
            {/* <Link  to="/hr/">
                <div className="menu">
                    Hr
                </div>  
            </Link> 
            
            <Link  to="/committe/">
                <div className="menu">
                    Committe
                </div>  
            </Link>  */}

            <NavLink   activeClassName="selected_route"  exact to="/license/">
                
            <div  className="menu">  License</div>
                
            </NavLink > 

            <NavLink  activeClassName="selected_route" exact to="/plans/">
               
               <div className="menu">Plans</div>
                     
               </NavLink > 
            <NavLink   activeClassName="selected_route" exact to="/section/">
              
            <div  className="menu">Section</div>
               
            </NavLink >
            <NavLink  activeClassName="selected_route" exact to="/assignment/">
                
            <div  className="menu">  Assignment</div>
                
            </NavLink >

            <NavLink   activeClassName="selected_route" exact to="/notification/">
              
              <div  className="menu">Notification</div>
                 
              </NavLink >

            {/* <NavLink   to="/complain/">
                <div className="menu">
                    Complain
                </div>  
            </NavLink > */}

            {/* <NavLink  activeClassName="selected_route" exact  to="/complaint/">
                
            <div className="menu">Complaint</div>
              
            </NavLink >

            <NavLink   activeClassName="selected_route" exact to="/settings/">
                
                   <div className="menu">Settings</div> 
                
            </NavLink > */}
             
            <NavLink  activeClassName="selected_route" exact to="/logout/">
               
            <div className="menu">Logout</div>
                  
            </NavLink > 



            
            <div id="mySidenav" className="sidenav ">
                {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
                <a href=""><div className="collapse-menu">Branch</div></a>
                {/* <a href=""><div className="collapse-menu">Hr</div></a> */}
                <a href=""><div className="collapse-menu">Employees</div></a>
                <a href=""><div className="collapse-menu">Home</div></a>

            </div>                           
        </div>
         <div className="col m-0 p-0 right-side  mt-5">
{/*     
            <div className="row search-nav">
                <div className="col-sm-3">
                    <p> Branch records</p>
                </div>
                <div className="col-sm-3 m-auto">




                    <div className="add-branch">


                        <button type="button" onClick={this.onModalChange}>
                            <i className="fas fa-plus"></i>
                            <strong> Add </strong>
                        </button> 
                    </div>


                </div>
                <div className="col-sm-5 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><img src={search} width="25" height="25" alt="search"/></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div>  */}
            <Switch>
                    
                    
                    <Route path="/company/" exact component={Wrapper(Company)}/> 
                    <Route path="/home/" exact component={Wrapper(Home)}/>  
                    <Route path="/branch/" exact component={Wrapper(Branch)}/>  
                    {/* <Route path="/hr/" exact component={Wrapper(HR)}/> 
                    <Route path="/committe/" exact component={Wrapper(CompanyCommitte)}/>   */}
                    <Route path="/license/" exact component={Wrapper(License)}/>  
                    <Route path="/complaint/" exact component={Wrapper(Complaint)}/>
                    <Route path="/settings/" exact component={Wrapper(Settings)}/>
                    <Route path="/complaindetail/" exact component={Wrapper(ComplainDetail)}/> 
                    <Route path="/complain/" exact component={Wrapper(Complain)}/> 
                    <Route path="/logout/" exact component={Wrapper(Logout)}/>  
                    <Route path="/plans/" exact component={Wrapper(Plans)}/> 

                    <Route path="/section/" exact component={Wrapper(Section)}/> 
                    <Route path="/assignment/" exact component={Wrapper(Assignment)}/>
                    <Route path="/notification/" exact component={Wrapper(Notification)}/>

                    <Route path="/assignment/:id" exact component={Wrapper(Students)}/>   
                    <Route path="/assignment/:id/students" exact component={Wrapper(Student_List)}/>   
                    <Route path="/assignment/:id/:sid/view" exact component={Wrapper(View_Submission)}/>   

                    <Route path="/assignment/:id/:sid" exact component={Wrapper(Student_Answer)}/>   

                    
                    <Route path="/:id" exact component={Wrapper(Chapter)}/>  
                    <Route path="/:id/:name" exact component={Wrapper(SubSection)}/>  

                </Switch>

            </div> 
    </div>    



            </React.Fragment>:
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
    
        {/* <div className="logo"> <img src={logo} className="myimg ml-5" alt="logo"/></div> */}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">      
            <header className="container-fluid">
                <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-5 header-right">
                    <Link to="/"> Login</Link>
                    </div>  
                </div>
            </header>
        </div>
    </nav>

            <Route path="/" exact component={Wrapper(Login)}/>  
            <Route path="/thankyou-logout/" exact component={Wrapper(ThankyouLogout)}/> 

            {/* <Route path="/thankyou-logout/" exact component={Wrapper(ThankyouLogout)}/>  */}
                </div> }
            </div>
            
        )
    }
}


        
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">      
            <header className="container-fluid">
                <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-5 header-right">
                    <Link to="/"> Signup</Link>
                    </div>  
                </div>
            </header>
        </div>
    </nav>

            <Route path="/" exact component={Wrapper(Signup)}/>  
            <Route path="/login/" exact component={Wrapper(Login)}/>  
            <Route path="/thankyou-logout/" exact component={Wrapper(ThankyouLogout)}/> 
                </div> }
            </div>
            
        )
    }
} */}


const mapStateToProps = (state) => {
    return {
       logo_check:state.logo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        change: (username, password, props) => dispatch(actions.authLogin(username, password,props)) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);