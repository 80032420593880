import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
function View_Notification(props)
{
    const [data,SetData] = useState({id:'',topic:'',
notice:''})



const callMe=(e)=>
{

  SetData({id:props.id,
    topic:props.topic,
    notice:props.notice
  })
  console.log(data)
}


  const onChange=(e)=>
  {
  
    SetData({...data,[e.target.name]:e.target.value
    })
  }
  

    const onSubmitForm=(e)=>
    {
     e.preventDefault();
    const id = data.id
    const token = localStorage.getItem('token')
    const headers = {
      // "Content-Type": "multipart/form-data",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    e.preventDefault();
  

    let formData = new FormData();   

    formData.append('topic', data.topic);
    formData.append('notice', data.notice);

    console.log(id)
    axios.put(`${process.env.REACT_APP_API_URL}license/api/add-notice/?notice=${id}`,formData, {headers : headers})
    .then(response => {
        if(response.statusText==='OK'){
          window.location.reload();
        }
        console.log(response)
        // }
     
    })
    .catch(err => {
      console.log(err)
    });
    }


  const deleteMe=()=>
  {
   
  const id = props.id
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  console.log(id)
  axios.delete(`${process.env.REACT_APP_API_URL}license/api/add-notice/?notice=${id}`, {headers : headers})
  .then(response => {
      if(response.statusText==='OK'){
        window.location.reload();
      }
      console.log(response)
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  }
return(
<>  
<div className="col-lg-4">

              <div className="card">
            
  <div className="card-body">
    {props.topic} 

<span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}} onClick={callMe} data-toggle="modal" data-target={`#edit${props.id}`}> <i  className="fa fa-eye" aria-hidden="true"></i> 
</span>

<span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  onClick={deleteMe}> <i  className="fa fa-trash" aria-hidden="true"></i> 

</span>
</div>
</div>
</div>






<div className="modal fade" id={`edit${props.id}`} tabIndex="-1" role="dialog" aria-labelledby={`edit${props.id}`}  aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Notification {props.id}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">





<form onSubmit={(event) => onSubmitForm(event)}>

<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="topic" className="email">Topic</label>
            <input type="text" className="form-control" name="topic" id="topic" aria-describedby="topic" placeholder="Topic Name"
            required="required"
            value={data.topic}
            onChange={onChange}/>
          </div>
      </div>



    <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notice" className="email">Description</label>
            <textarea rows="5" cols="2" className="form-control" name="notice" id="notice" aria-describedby="notice"
            required="required"
            value={data.notice}
            onChange={onChange}/>
          </div>
      </div>

   

        </div>

        
           {/* <p style={{    wordWrap: "break-word"}}> */}
        
          
     
   
        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>

{/* 
</form> */}


</form>



    </div>

</div>
</div>
</div>





</>
  )

  
}


export default View_Notification;