import React from 'react';
import axios from 'axios';
import '../Branch/BranchCreate.css';
import Chapters from "./Chapter_List";
import {Link} from "react-router-dom";
import Spinner from '../../../Component/spinner';
import {message} from "antd"
class Chapter extends React.Component {
  constructor(props){
  super(props);
    this.state = {
      chapter_name : '',
      data:[],
      load:false,
      search:'',

  }
  }


  componentDidMount(){
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    
    const id=this.props.location.state;
    axios.get(`${process.env.REACT_APP_API_URL}learning/api/add-chapter/?course=${id}`, {headers : headers})
    .then(response => {
      
      this.setState({data:response.data.data})

     
    })
    .catch(err => {
      console.log(err)
    });
  }


  onFormSubmit = (event) => {

    event.preventDefault();
    this.setState({...this.state,load:true})

    const data = {
      title : this.state.chapter_name,
      course:this.props.location.state 

    }
    const token = localStorage.getItem('token')
    console.log(token)
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }

    axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-chapter/`, data, {headers : headers})
    .then(response => {
      if(response.data.status==='success'){
        window.location.reload();
      }
      // console.log(response)
    })
    .catch(err => {
      console.log(err)
      this.setState({load:false})
      message.warning("Some error occured");

    });
    
}


  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
    console.log(this.state)
  }



  SearchItem = e => {
    const searchvalue=e.target.value;
    this.setState({search:searchvalue})
  }

  render () {


    let item=[];
    item =this.state.data.filter((innerdata)=>{
      if(this.state.search == '')
          return innerdata
      else if(innerdata.title.toLowerCase().startsWith(this.state.search.toLowerCase())) {
          return innerdata
      }
    });


    return (
      <div className="p-0">
{console.log(this.props)}

   
<div className="search-box py-2 px-2 ">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col-lg-6 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                  <ul className="breadcrumbs">
                  <li><Link className="text-white" to="/section/">Subjects</Link></li>
                  <li><Link className="text-white" to={{
                    pathname: `/${this.props.match.params.id}`,
                    state:  this.props.location.state,
                  }}>{this.props.match.params.id}</Link></li>
                
                  {/* <Link className="text-white" to="/section/">Subjects</Link>/{this.props.match.params.id} */}
                  </ul>
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 col-0">
                    {/* <select className="form-control select-field w-100">
                      <option>Select Fields</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select> */}
                  </div> 

                  <div className="col-lg-1 col-md-4 col-sm-4  col-6">

                  
                  </div>

                
                  <div className="col-lg-3 col-md-4 col-6  col-sm-4">
                    <div className="input-group md-form form-sm form-2 pl-0">
                      <input className="form-control my-0 py-1 lime-border" type="text" placeholder="Search" name="search" onChange={this.SearchItem} aria-label="Search"/>
                      <div className="input-group-append">
                        <span className="input-group-text lime lighten-2" id="basic-text1"><i className="fas fa-search"
                            aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>






<div className="row">
<div className="col-lg-4">
              <div className="card">
  <div className="card-body " style={{cursor: "pointer"}}  data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Add Chapters

  </div>
</div>
</div>
{/* {this.state.data.map((d)=><Chapters key={d.id} id={d.id} title={d.title} course={d.course} sub={this.props.match.params.id} chap={this.props.location.state}/>
)} */}

{item.map((d)=><Chapters key={d.id} id={d.id} title={d.title} course={d.course} sub={this.props.match.params.id} chap={this.props.location.state}/>
)}

{/* <Chapters sub={this.props.match.params.id} name="Algebra"/>
<Chapters sub={this.props.match.params.id} name="Trignometry"/>
<Chapters sub={this.props.match.params.id} name="Calculus"/> */}

 


</div>



      {/* <div className="row search-nav">
                <div className="col-sm-4">
                    <p> License Records</p>
                </div>
                <div className="col-sm-3 m-auto">

                    <button type="button" class="btn p-0 m-0 btn-sm pop-btn" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fas fa-plus mr-1"></i>Add License
                    </button>

                </div>
                <div className="col-sm-4 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><i class="fas fa-search"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div> */}
        {/* **************************** */}
 


      








<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Chapter</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      {this.state.load? (<div className="loading">
  <Spinner/>
  </div>):("")}
    <div className="row">
        <div className="col m-auto p-3 form-div">
          {/* <div className="row">
            <h3>Add License</h3>
            <div className="col cancel">
              <i className="fas fa-times pr-2"></i>Cancel
            </div>
          </div> */}
{/* 
      <form onSubmit={(event) => this.onFormSubmit(event)}>

      <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="branch" className="email">Branch Id</label>
                  <input type="number" className="form-control" name="branch" id="branch" aria-describedby="emailHelp" placeholder="Branch id"
                  required="required"
                  value={this.state.branch}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


         <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="first_name" className="email">Name</label>
                  <input type="text" className="form-control" name="first_name" id="first_name" aria-describedby="emailHelp" placeholder="Name"
                  required="required"
                  value={this.state.first_name}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="mobile" className="email">Mobile</label>
                  <input type="number" className="form-control" name="mobile" id="mobile" aria-describedby="emailHelp" placeholder="Mobile"
                  required="required"
                  value={this.state.mobile}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="email" className="email">Email</label>
                    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Email" required="required"
                     data-error="Please enter a valid email."
                    value={this.state.email}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


        <div className="row">
              <div className="col">
                  <center> <button type="submit" className="btn">Save</button></center> 
              </div>
        </div>



      </form> */}





<form onSubmit={(event) => this.onFormSubmit(event)}>


<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="chapter_name" className="email">Chapter Name</label>
            <input type="text" className="form-control" name="chapter_name" id="chapter_name" aria-describedby="chapter_name" placeholder="Chapter  Name"
            required="required"
            value={this.state.chapter_name}
            onChange={this.onChange}/>
          </div>
      </div>


    
        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>
  </div>



</form>






        </div>
        
    </div>





    </div>

</div>
</div>
</div>


      </div>
    )
  }
}



export default Chapter;