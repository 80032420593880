import React from 'react';
import Table from '../../../Component/licensetable';
import axios from 'axios';
import Spinner from '../../../Component/spinner';
import {Pagination} from "antd";

class LicenseList extends React.Component {
    state = {
        licenses : [],
        subscriptionplans:[],
        error : false,
        search:'',
        load:false,
        pages:null,
        current_page:1,
    }

    componentDidMount() {
        this.setState({load:true})
        const token = localStorage.getItem('token')
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
       this.renderlicense();

        axios.get( `${process.env.REACT_APP_API_URL}subscription/api/subscription-plans/`, {headers:headers} )
        .then( response => {
            this.setState({subscriptionplans:response.data.data});
            
      
        } )
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
      }
      
      // renderlicense=(pageno=1)=>{
      //   this.setState({load:true})
      //   const token = localStorage.getItem('token')
      //   console.log(token)
      //   const headers = {
      //     "Content-Type": "application/json",
      //     "AUTHORIZATION": `Token ${token}`
          
      //   }
      //   axios.get( `${process.env.REACT_APP_API_URL}license/api/license/?page=${pageno}`, {headers:headers} )
      //   .then( response => {
      //       this.setState({load:false})

      //       this.setState({licenses:response.data.license_list.results,pages:response.data.license_list.count});
      //       console.log("TESTING THIS" ,response.data);
      //   } )
      //   .catch(error => {
      //       console.log(error);
      //       this.setState({error: true});
      //   });

      // }


      renderlicense=(pageno=1,search='')=>{
        this.setState({load:true})
        const token = localStorage.getItem('token')
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        axios.get( `${process.env.REACT_APP_API_URL}license/api/license-filter/?page=${pageno}&search=${search}`, {headers:headers} )
        .then( response => {
            this.setState({load:false})

            this.setState({licenses:response.data.results,pages:response.data.count,current_page:pageno});
        } )
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });

      }
      
      searchSpace=(event)=>{
        let keyword = event.target.value;
        this.setState({search:keyword})
      }

     
      changePage=(page, pageSize)=>{
          this.setState({current_page:page})
          this.renderlicense(page,this.state.search)
      }


    render() {
        const licenses = this.state.licenses;

        const items =licenses.filter((data)=>{
       return data
        
        }).map(data=>{
          return(
              <Table
                  id={data.uuid}
                  key={data.uuid} 
                  name={data.user_profile.full_name}
                  license={data.license}
                  email={data.user_profile.email}
                  batch={data.user_profile.batch}
                  gender={data.user_profile.gender}
                  mobile={data.user_profile.mobile}
                  status={data.license_status}
                  active={data.active_license}
                  plans={this.state.subscriptionplans}

                  
                  />
          
          )
        })



        // const items =licenses.filter((data)=>{
        //     if(this.state.search == '')
        //         return data
        //     else if(data.user_profile.full_name.toLowerCase().startsWith(this.state.search.toLowerCase()) || data.user_profile.email.toLowerCase().startsWith(this.state.search.toLowerCase())){
        //         return data
        //     }
        //   }).map(data=>{
        //     return(
        //         <Table
        //             id={data.uuid}
        //             key={data.uuid} 
        //             name={data.user_profile.full_name}
        //             license={data.license}
        //             email={data.user_profile.email}
        //             batch={data.user_profile.batch}
        //             gender={data.gender}
        //             status={data.license_status}
        //             active={data.active_license}
        //             plans={this.state.subscriptionplans}

                    
        //             />
            
        //     )
        //   })
      
         





        // {console.log("My state",this.state)}
        // let license = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        // if (!this.state.error) {
        //     license = this.state.licenses.map(li => {
        //         return <Table
        //             id={li.UUID}
        //             key={li.UUID} 
        //             name={li.name}
        //             license={li.license}
        //             email={li.email}
        //             batch={li.batch}
        //             gender={li.gender}
        //             status={li.license_status}

        //             plans={this.state.subscriptionplans}

                    
        //             />
        //     });





        //}

       
        return (
            <div className="row main-content">
             <div className="col-6 "></div>
            <div className="col-6 mt-3 mb-3">
                    <div className="input-group md-form form-sm form-2 pl-0">
                      <input className="form-control my-0 py-1 lime-border" type="text" placeholder="Search" aria-label="Search" onChange={(e)=>this.searchSpace(e)}/>
                      <div className="input-group-append">
                        <span className="input-group-text lime lighten-2" id="basic-text1" style={{cursor:'pointer'}} onClick={()=>this.renderlicense(1,this.state.search)}><i className="fas fa-search"
                            aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>

               
            <div className="col-12" style={{height:"60vh",overflow:"auto"}}>
            {/* <table className="row-space text-left pd-5"> */}
            <table className="table table-striped">
            <thead>
                <tr> 
                    {/* <th scope="col">Branch Id</th> */}
                    <th scope="col">Full Name</th>
                    <th scope="col">License no</th>
                    <th scope="col">Email </th>
                    <th scope="col">License Status</th>
                    <th scope="col">Active</th>

                    

                   
                   
                    
                </tr>
            </thead>
            {this.state.load &&
           
           <Spinner/>
           }
        
          {!this.state.load && <tbody>
                {items}
                </tbody>
          }
            </table>

            </div>
            <div className="col-12 mt-5">
            <Pagination current={this.state.current_page} pageSize={50} defaultCurrent={1} total={this.state.pages} onChange={this.changePage}	/>
            </div>
            </div>



        )
    }
}

export default LicenseList;