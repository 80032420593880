import React from 'react';
import Table from '../../../Component/HRtable';
import './Hr.css';
import axios from 'axios';

class HrList extends React.Component {
    state = {
        hr : [],
        error : false
    }

    componentDidMount() {
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        axios.get( `${process.env.REACT_APP_API_URL}companies/api/hr/`, {headers:headers} )
        .then( response => {
            this.setState({hr:response.data});
            console.log( response.data );
        } )
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }

    render() {
        let branches = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            branches = this.state.hr.map(h => {
                return <Table
                    key={h.id} 
                    sno={h.id}
                    username={h.company}
                    mobile={h.mobile}
                    email={h.email}/>
            });
        }

        return (
            <div className="row main-content">
            <div className="col">
            <table className="row-space">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Username</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email</th>
                </tr>
                </thead>
                {branches}
            </table>
            </div>
            </div>
        )
    }
}

export default HrList;