import React,{useState,useEffect} from 'react';

import axios from 'axios';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {message} from "antd"
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));


const Remarks =(props)=> {


  const classes = useStyles();
  const [opens, setOpens] = React.useState(false);

//   const handleClicks = () => {
//     setOpens(true);
//   };

  const handleCloses = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpens(false);
  };



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      submitme();
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    



    const [data, setData] = useState([]);
    
    useEffect(()=>{
      const token = localStorage.getItem('token')
  
      const headers = {
        "Content-Type": "application/json",
        "AUTHORIZATION": `Token ${token}`
        
        }
        const id = props.id;
      const sid = props.sid;

    axios.get(`${process.env.REACT_APP_API_URL}learning/api/test-series-sub-answers/?testseries=${id}&user_id=${sid}`, {headers : headers})
    .then(response => {
      
      setData(response.data.data)
    })
    .catch(err => {
      console.log(err)
    });
    },[])

    const marks =(event)=>
    {
        const id =parseInt(event.target.name)
        let marks= event.target.value
        const question = event.currentTarget.dataset.id
        if((isNaN(marks) || marks==''))
           {
            marks=0
           }
      const newState = data.map(obj =>
        obj.id === id ? { ...obj, "marks": marks} : obj
    );
    setData(newState)

    }

    const remarks =(event)=>
    {
        const id =parseInt(event.target.name)
        const remarks= event.target.value
        const question = event.currentTarget.dataset.id


      const newState = data.map(obj =>
        obj.id === id ? { ...obj, "remarks": remarks} : obj
    );
    setData(newState)

    }

    // const marks =(event)=>
    // {
    //   console.log(event.target.name)
    //   console.log(event.target.value)
    //   if(isNaN(event.target.value))
    //   {
    //     event.target.value=''
    //   }
    //   else
    //   setMarks({...mark,[event.target.name]:event.target.value})
    // }
    // const remarks =(event)=>
    // {
    //   console.log(event.target.name)
    //   console.log(event.target.value)
    //   setRemarks({...remark,[event.target.name]:event.target.value})
    // }
    const submitme=(event)=>{
      // event.preventDefault()
       
      //  console.log(Object.keys(remark))
      //  console.log(Object.keys(mark))
      //  Object.keys(mark).map((d,i)=>
      //   {
      //       if(remark[d]===undefined)
      //       {remark[d]="None"}
      //   })
      //   console.log(Object.values(remark))
      //  console.log(Object.values(mark))
      //   let ds=[]
      //   Object.keys(mark).map((d,i)=>
      //   {
      //       ds.push({"id":d,
      //       "marks":mark[d],
      //       "remarks":remark[d]
      //   })
        
          
      //   })


        // Object.keys(remark).map((d,i)=>
        // {
        //     ds.concat({...ds,
        //     "remarks":remark[d]
        // })
        
          
        // })
      message.loading("Saving Answers..")

        let datas={
          
            "test_id":props.id,
            "user_id":props.sid,
            "data":data
    }



    const token = localStorage.getItem('token')

        const headers = {
            "AUTHORIZATION": `Token ${token}`
            
          }
          axios.post( `${process.env.REACT_APP_API_URL}learning/api/test-series-sub-answers/`,datas, {headers:headers} )
          .then( response => {
            if(response.statusText==='OK')
            {
              message.destroy()
              setOpens(true);

            }
          } )
          .catch(error => {
              console.log(error);
              message.error("Some error occurred..")
          });



    }

    const finalsubmit=(event)=>{
      // setOpens(true);
     
      message.loading('Saving your answer..')
     
        const token = localStorage.getItem('token')
  
        const headers = {
            "AUTHORIZATION": `Token ${token}`
            
          }
          axios.post( `${process.env.REACT_APP_API_URL}learning/api/test-series-finished-checking/?test_series=${props.id}&is_checked=True&user_id=${props.sid}`,null, {headers:headers} )
          .then( response => {
            if(response.statusText==='OK')
            {
                message.destroy()
                message.success("Answer sheet submitted successfully")
                props.history.push("/assignment");
            }
          } )
          .catch(error => {
              console.log(error);
          });



    
    } 

        return (
          <>
           <div classname="row">
               
               <div className="col mb-2">
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Final Submission
         </Button>
         </div>
               
             </div>
            <div style={{height:"100vh",overflow:'auto'}}>
               
                            <ul className="list-group">
                           
                            {data.map(d=> <><li key={d.id} style={{backgroundColor: "#f6f6f6"}} className="col list-group-item"><label htmlFor={`question${d.id}`}>Q:{d.question_no}:</label> <textarea style={{width:"100%"}} id={`question${d.id}`} cols="10" name={d}  className="pr-3" rows="5" readOnly value={d.answer}/></li>
                            <li className="col list-group-item " style={{backgroundColor: "#f6f6f6"}}>
                            <label htmlFor={`remark${d.id}`}> Remarks: </label><input style={{width:"100%"}} name={d.id} data-id={d.question_no}  value={d.remarks} onChange={remarks} /></li>
                            <li key={`r${d.id}`} className="col list-group-item mb-2" style={{backgroundColor: "#f6f6f6"}}>
                            <label htmlFor={`mark${d.id}`}> Marks: </label><input key={`m${d.id}`} type="text" style={{width:"100%"}} name={d.id} data-id={d.question_no}  value={d.marks} onChange={marks} /></li></>)}

                            </ul>
                            
                           
       {/* <div className="col">
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
       Final Submission
      </Button>
      </div> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to make final submission?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          After this you will not be able to make changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
          <Button onClick={finalsubmit} color="primary" autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    <div className="row mb-5">
    <div className="col-6"></div>
            <div className="col mt-2 mb-5">
      <Button variant="outlined" color="primary" onClick={submitme}>
       Save </Button>
       </div>



     <div className={classes.root}>
     {/* <Button variant="outlined" onClick={handleClicks}>
       Save
      </Button> */}
      <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
        <Alert onClose={handleCloses} severity="success">
          Changes Saved!
        </Alert>
      </Snackbar>
    
    </div>

                            
                        </div>
                        </>
        )
   
}
export default Remarks;