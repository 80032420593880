import React from 'react';

const hrtable = (props) => (
    <tbody>
        <tr className="solid">
            <th scope="row" >{props.sno}</th>
            <td>{props.username}</td>
            <td>{props.mobile}</td>
            <td>{props.email}</td>
        </tr>
    </tbody>
)

export default hrtable