import React, { Component } from 'react';
import './ComplainDetail.css';

const ComplainDetail = (props) => {
    const timeline = {
        registered: true,
        mail_sent_to_hr: false,
        mail_sent_to_director: false,
        resolved: false,
    }

    return (
        <div>
            <div className="search-box py-2 ">
                <div className="row mx-0 px-0 my-0 py-0">

                    <div className="col-lg-3 col-md-3 col-sm-4 text-center text-lg-left text-md-left text-sm-left mt-2">
                        Branch Records
                        </div>
                </div>
            </div>

            <h5>Complaint Timeline</h5>
            <div className="row">
                <div className="col-md-3">
                    <div className="separator separator-green">
                        <i className="fas fa-check text-success check-fs" />
                    </div>
                    <h6 className="center text-success">Complaint Registered</h6>
                </div>
                <div className="col-md-3">
                    <div className={`separator ${timeline.mail_sent_to_hr || timeline.resolved ? `separator-green` : `muted-cl`}`}>
                        <i className={`fas fa-check check-fs ${timeline.mail_sent_to_hr || timeline.resolved ? `text-success` : `muted-cl`}`} />
                    </div>
                    <h6 className={`center ${timeline.mail_sent_to_hr || timeline.resolved ? `text-success` : `muted-cl`}`}>Mail sent to HR</h6>
                </div>
                <div className="col-md-3">
                    <div className={`separator ${timeline.mail_sent_to_director || timeline.resolved ? `separator-green` : `muted-cl`}`}>
                        <i className={`fas fa-check check-fs ${timeline.mail_sent_to_director || timeline.resolved ? `text-success` : `muted-cl`}`} />
                    </div>
                    <h6 className={`center ${timeline.mail_sent_to_director || timeline.resolved ? `text-success` : `muted-cl`}`}>Mail sent to Director</h6>
                </div>
                <div className="col-md-3">
                    <div className={`separator ${timeline.resolved ? `separator-green` : `muted-cl`}`}>
                        <i className={`fas fa-check check-fs ${timeline.resolved ? `text-success` : `muted-cl`}`} />
                    </div>
                    <h6 className={`center ${timeline.resolved ? `text-success` : `muted-cl`}`}>Resolved</h6>
                </div>
            </div>

            <div class="row ">
                <div class="col m-auto">
                    <div class="row p-3 complain">
                        <div class="col ">
                            <div class="row pt-5">
                                <div class="col-md-4 pb-0">
                                    <div class="row p-5">
                                        <div class="col heading pb-2 px-2 py-5">Complainant</div>
                                        <div class="col content pb-2 px-2 py-5">{props.name}</div>
                                    </div>
                                </div>

                                <div class="col-md-4 pb-0">
                                    <div class="row">
                                        <div class="col heading pb-2 px-2 py-5">Severity</div>
                                        <div class="col content pb-2 px-2 py-5">{props.severity}</div>
                                    </div>
                                </div>

                                <div class="col-md-4 pb-0">
                                    <div class="row">
                                        <div class="col heading pb-2 px-2 py-5">Complaint Date</div>
                                        <div class="col content pb-2 px-2 py-5">{props.date}</div>
                                    </div>
                                </div>

                            </div>

                            {/* <!-- seconf colon --> */}
                            <div class="row">

                                <div class="col-md-4 pt-0 pb-3">
                                    <div class="row pt-2 pb-2">
                                        <div class="col heading pt-1 py-1">Times Repeated</div>
                                        <div class="col content pt-1 py-1">1</div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                </div>

                                <div class="col-md-4">
                                </div>

                            </div>
                            {/* <!-- third row --> */}
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col heading py-2">Accused Name</div>
                                        <div class="col content py-2">{props.accussed_name}</div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col heading py-2">Accused Department</div>
                                        <div class="col content py-2">{props.accussed_department}</div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col heading py-2">Accused Position</div>
                                        <div class="col content py-2">{props.accussed_position}</div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- fourth row --> */}
                            <div class="row">
                                <div class="col">
                                    <div class="row ">
                                        <div class="col heading text-left">
                                            <p >Incident</p>
                                            <div class="message-content">
                                                <p>
                                                    {props.description}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <!-- <div class="col content">Full Name</div> --> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- messages --> */}

                            {/* <!-- button --> */}
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12  pt-3 pb-3">

                                            <div class="heading" >Attachment</div>
                                            <button type="button" class="btn btn-view ml-2 mr-2 text-white">View</button>
                                            <button type="button" class="btn ">Download</button>


                                        </div>
                                        <div class="col-md-6 col-sm-12  pt-3 pb-3 content">
                                            <div class="row">
                                                <div className="col">
                                                    <div class="heading" >Set Status:</div>
                                                </div>
                                                {/* <div class="dropdown  ml-3 mr-3" >
                              <button class="btn dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Pending
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <li>
                                <ul><a class="dropdown-item" href="#"><center><strong>Resolved</strong></center></a></ul>
                                <ul><a class="dropdown-item" href="#">Action2</a></ul>
                                <ul><a class="dropdown-item" href="#">Action3</a></ul>
                                </li>
                              </div>
                             </div> */}
                                                <div className="col">
                                                    <div class="form-group">
                                                        <select id="inputState" class="form-control">
                                                            <option>Pending</option>
                                                            <option>Resolved</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <button type="button" class="btn btn-save text-white" >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--  input type --> */}
                            <div class="row">
                                <div class="col comment  heading">
                                    <p>Add Comment</p>
                                    <input type="text" />
                                </div>
                            </div>

                            {/* <!-- last button --> */}
                            <div class="row">
                                <div class="col p-3 comment-btn mr-3 ">
                                    <center>
                                        <input type="button" value="Cancel" class="btn btn-style pt-1" />
                                        <input type="button" value="Comment" class="btn btn-style pt-1" />
                                    </center>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </div>

    )

}

export default ComplainDetail
