import React,{useState} from 'react';
import axios from 'axios';
import LicenseList from './LicenseList';
import '../Branch/BranchCreate.css';
import Subject from './Subject';
import {Link} from "react-router-dom";
import Spinner from '../../../Component/spinner';
import {message} from "antd"

function Chapter_List(props)
{ 
  const [data,SetData] = useState({
    title:'',
    course:'',



  });

  const [load,setLoad]=useState(false);

  const deleteMe=()=>
  {
   
  const id = props.id
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  console.log(id)
  axios.delete(`${process.env.REACT_APP_API_URL}learning/api/add-chapter/?id=${id}`, {headers : headers})
  .then(response => {
      if(response.statusText==='OK'){
        window.location.reload();
      }
      console.log(response)
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  }

  const callMe=(e)=>
  {

    SetData({title:props.title,
    course:props.course
    })
    console.log(data)
  }

  const changed=(e)=>
  {
    SetData({...data,[e.target.name]:e.target.value});
    console.log(data)

  }



  
const onClickHandler = (event) => {
  event.preventDefault();
  setLoad(true)

  const inputs = new FormData() 
  
  inputs.append('title',  data.title)
  inputs.append('course',data.course)
  console.log(inputs)

const id = props.id
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
console.log(inputs)
console.log(id)
axios.put(`${process.env.REACT_APP_API_URL}learning/api/add-chapter/?id=${id}`, inputs, {headers : headers})
.then(response => {
    if(response.statusText==='OK'){
      window.location.reload();
    }
    // }
 console.log(response)
})
.catch(err => {
  console.log(err)
  setLoad(false)

  message.warning("Some error occured")
});

}
  return(
  <>  
  <div className="modal fade" id={`c${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
  <h5 className="modal-title" id="exampleModalLongTitle">{`Edit ${data.title}`}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      {load?
  (<div className="loading">
  <Spinner/>
  </div>):("")
}
<form onSubmit={onClickHandler}>
<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="title" className="email">Chapter Name</label>
            <input type="text" className="form-control" name="title" id="title" aria-describedby="title"
            required value={data.title} onChange={changed}/>
          </div>
      </div>
      <div className="col">
            <center> <button type="submit" className="btn btn-info " >Save</button></center> 
        </div>
        

</div>
</form>
  </div>
  </div>
  </div>
  </div>
  
<div className="col-lg-4">

              <div className="card">
             

  <div className="card-body">
  {/* <Link className="text-dark" to={`${props.sub
}/${props.name}`}> */}
<Link className="text-dark" to={{
     pathname: `${props.sub}/${props.title}`,
     state: props.id,
     prev:props.chap
}}>  
  
  
  
   {props.title}  </Link><span className="float-right" style={{cursor:"pointer"}}name={props.title}  data-toggle="modal" data-target={`#c${props.id}`} onClick={callMe}>  <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
</span><span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  onClick={deleteMe}> <i  className="fa fa-trash" aria-hidden="true"></i> 

</span>

  </div>
 
</div>
</div>
</>
  )

  
}


export default Chapter_List;