import React from 'react';
import Table from '../../../Component/table';
import axios from 'axios';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';

class BranchList extends React.Component {
    state = {
        branches : [],
        error : false
    }

    componentDidMount() {
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        // axios.get( 'http://127.0.0.1:8000/companies/api/branch/', {headers:headers} )
        axios.get( `${process.env.REACT_APP_API_URL}companies/api/branch/`, {headers:headers} )
        .then( response => {
            this.setState({branches:response.data});
            const logo=response.data[0].logo
            localStorage.setItem('logo',logo)
            console.log( response.data );
            console.log(process.env.REACT_APP_API_URL)
            this.props.change()
        })
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }

    render() {
        let branches = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            branches = this.state.branches.map(branch => {
                return <Table
                    key={branch.id} 
                    sno={branch.id}
                    company={branch.company}
                    city={branch.city}
                    name={branch.name}
                    mobile={branch.mobile}
                    email={branch.email}
                    />
            });
        }

        return (
            <div className="row main-content">
                
            <div className="col">
            <table className="row-space">
            <thead>
                <tr className="rounded">
                    <th scope="col">S.No</th>
                    <th scope="col">Branch Name</th>
                    <th scope="col">Branch City</th>
                    <th scope="col">Contact Person</th>
                    <th scope="col">Branch mobile</th>
                    <th scope="col">Branch Email</th>
                </tr>
                </thead>
                {branches}
            </table>
            </div>
            </div>
        )
    }
}

// export default BranchList;




const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(actions.logocheck()) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BranchList);