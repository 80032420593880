import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {message} from 'antd'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));




const Student_Answer_PDF =(props)=> {
    const [data, setData] = useState('None');
    const [file, setFile] = useState("");

    const [id,setId]=useState(-1)
    const [name,setName]=useState("");


    const classes = useStyles();
    const [opens, setOpens] = React.useState(false);
  
  //   const handleClicks = () => {
  //     setOpens(true);
  //   };
  
    const handleCloses = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpens(false);
    };

  

    useEffect(()=>
    {
      const token = localStorage.getItem('token')

    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`
      
      }
      axios.get( `${process.env.REACT_APP_API_URL}learning/api/test-series-checked-sheet/?testseries=${props.id}&user_id=${props.sid}`, {headers:headers} )
      .then( response => {
        const extract_name= response.data.data.checked_sheet!='N/A'?response.data.data.checked_sheet.substring(response.data.data.checked_sheet.lastIndexOf("/")+1):"None"

              setData(extract_name)
              setName(extract_name)
                 
      } )
      .catch(error => {
        console.log(error);
      });
    },[])

   

    const onFileChange = (event) =>
    {
      if(event.target.files[0]!=undefined){
      setData(event.target.files[0].name);
      setFile(event.target.files[0]);
      }
      else{
        setData(name)
        setFile("");

      }
      
    }
const reset=(event)=>
{
  setData(name)
  setFile("");


}
    const submit = (event)=>
    {
      message.loading('Uploading File..');
      const token = localStorage.getItem('token')

        const headers = {
            "AUTHORIZATION": `Token ${token}`
            
          }
          if(typeof(file.name)!="undefined"){

          const formData = new FormData();
          formData.append("checked_sheet",file,file.name)

          axios.post( `${process.env.REACT_APP_API_URL}learning/api/test-series-checked-sheet/?testseries=${props.id}&user_id=${props.sid}`,formData, {headers:headers} )
          .then( response => {
               if(response.statusText==='OK')
               {
                setOpens(true);
                setName(file.name);
                message.destroy()

                message.success("Checked sheet uploaded succesfully.")



               }
          } )
          .catch(error => {
              console.log(error);
              message.error("Some error occured..")
          });
        }
        
    }
        return (
            <div>
            
              
                                            
                                <div className="input-group mb-5 mt-5">
                                <div className="input-group-prepend">
                                <span className="input-group-text" style={{backgroundColor: "#e9ecef",cursor:"pointer",
    borderRadius: "0%",
    border: "0px solid"}}onClick={reset}>Cancel</span>

    <span className={`${file!=''&& 'focused'} input-group-text mx-2`} style={{backgroundColor: "#e9ecef",cursor:"pointer",
    borderRadius: "0%",
    border: "0px solid"}} onClick={submit}>Upload Checked Sheet</span>
  </div>
  <div className="custom-file">
    <input type="file" className="custom-file-input" 
      aria-describedby="inputGroupFileAddon01" onChange={onFileChange}/>
    <label className="custom-file-label">{data}</label>

  </div>
</div>                           
                        

<div className={classes.root}>
     {/* <Button variant="outlined" onClick={handleClicks}>
       Save
      </Button> */}
      {/* <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
        <Alert onClose={handleCloses} severity="success">
          Answer Saved!
        </Alert>
      </Snackbar> */}
    
    </div>
    



</div>
                 
            
        )
   
}
export default Student_Answer_PDF;