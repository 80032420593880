import React from 'react';
import {Link} from 'react-router-dom';

const complaintable=(props)=> (
    <tbody>
    <tr className="solid">
        <th scope="row" onClick={props.clicked}><Link to = "/complain/">{props.sno}</Link></th>
        
        <td><Link to = "/complain/">{props.name}</Link></td>
        <td><Link to = "/complain/">{props.repeat_times}</Link></td>
        <td><Link to = "/complain/">{props.severity}</Link></td>
        <td><Link to = "/complain/">{props.accussed_name}</Link></td>
        <td><Link to = "/complain/">{props.date}</Link></td>
        <td><Link to = "/complain/">{props.status}</Link></td>
        
    </tr>
    </tbody>  


)
export default complaintable