import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"
import Spinner from '../../../Component/spinner';
import {message,Button} from "antd"
function Student(props)
{
  const [data,SetData] = useState({});
  const [topper,setTopper] = useState({topper_copy:{}});
  const [keys,setKey] = useState({answer_key:{}});
  const [load,setLoad] = useState(false);
  const [key,showKey]=useState(false);
  const [top,showTop]=useState(false);

  useEffect(()=>
  {
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    const id = props.match.params.id;
  axios.get(`${process.env.REACT_APP_API_URL}learning/api/teacher-test-series-view/?test_series=${id}`, {headers : headers})
  .then(response => {
    
    SetData(response.data.data)
    localStorage.setItem("assign_name",response.data.data.name)
  })
  .catch(err => {
    console.log(err)
  });
  }
,[])


const onChangeTopper=event=>{
if(event.target.files[0]!=undefined)
  setTopper({[event.target.name]: event.target.files[0],
  })
  else{
    setTopper({
      [event.target.name]: "",
    })
  }
}

const onChangeKey=event=>{
  if(event.target.files[0]!=undefined)
    setKey({[event.target.name]: event.target.files[0],
    })
    else{
      setKey({[event.target.name]: "",
      })
    }
  }


  
const submitKey=event=>{
  setLoad(true)
  if(keys.answer_key.name!=undefined)
  {
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": "application/json",
    "AUTHORIZATION": `Token ${token}`  
    
  }

  const id = props.match.params.id;
  const data = new FormData() 
  data.append('answer_key_pdf', keys.answer_key,keys.answer_key.name)
 
axios.put(`${process.env.REACT_APP_API_URL}learning/api/test-series/?test_series=${id}`,data, {headers : headers})
.then(response => {
  window.location.reload();
})
.catch(err => {
  message.error("Some error occured")
  console.log(err)
});
  
}
 }

  
const submitTopper=event=>{
  setLoad(true)
  if(topper.topper_copy.name!=undefined)
  {
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": "application/json",
    "AUTHORIZATION": `Token ${token}`  
    
  }

  const id = props.match.params.id;
  const data = new FormData() 
  data.append('topper_copy', topper.topper_copy,topper.topper_copy.name)
 
axios.post(`${process.env.REACT_APP_API_URL}learning/api/topper-copy/?id=${id}`,data, {headers : headers})
.then(response => {
  window.location.reload();
})
.catch(err => {
  console.log(err)
  message.error("Some error occured")

});
  
}
 }
return(
<>  
<main className="mt-5">
                <h1 className="text-center" style={{fontSize:"22px"}}>Assignment - {data.name}</h1>
                <ul className="list-group list-group-horizontal">
                <Link to={`/assignment/${props.match.params.id}`}><li className="list-group-item active" >Question</li></Link>
                <Link to={`/assignment/${props.match.params.id}/students`}><li className="list-group-item">Results</li></Link>

</ul>
</main>

<div className="mt-5 ml-5">
{load?
       (<div className="loading">
      <Spinner/>
      </div>):("")
      }
<p>Question PDF: <a href={data.question_paper} target="_blank">{data.question_paper!=undefined&&data.question_paper.substring(data.question_paper.lastIndexOf("/")+1)}</a>
</p>    
<p>Answer Booklet PDF: <a href={data.answer_booklet} target="_blank">{data.answer_booklet!=undefined&&data.answer_booklet.substring(data.answer_booklet.lastIndexOf("/")+1)}</a>
</p>    
<p>No of Questions: {data.no_of_questions}
</p>
{data.test_answer_key_pdf!='N/A'?(<p>Answer Key PDF: <a href={data.test_answer_key_pdf} target="_blank">{data.test_answer_key_pdf!=undefined&&data.test_answer_key_pdf.substring(data.answer_booklet.lastIndexOf("/")+1)}</a>  <Button onClick={()=>showKey(true)}> Upload Again ?</Button>

</p>):(<p>Answer Key PDF: <Button onClick={()=>showKey(true)}> Upload</Button>
</p>)    
}  
{key&&<div className="col-12">

<label htmlFor="answer_copy" className="email">Answer Key PDF Upload </label>
<input type="file" name="answer_key" onChange={onChangeKey}/>
<Button onClick={submitKey}> Upload</Button>


</div>  
}
{
  data.topper_copy!='N/A'?(<p>Topper Copy PDF: <a href={data.topper_copy} target="_blank">{data.topper_copy!=undefined&&data.topper_copy.substring(data.topper_copy.lastIndexOf("/")+1)}</a> <Button onClick={()=>showTop(true)}> Upload Again ?</Button>
</p>):(<p>Topper Copy PDF:<Button onClick={()=>showTop(true)}>  Upload ?</Button>
</p>)    
} 

{top&&
<div className="col-12">

<label htmlFor="topper_copy" className="email">Topper Copy PDF Upload </label>
<input type="file" name="topper_copy" onChange={onChangeTopper}/>
<Button onClick={submitTopper}> Upload</Button>

</div>
}
{/* <div className="col-12">

<label htmlFor="topper_copy" className="email">Topper Copy PDF Upload </label>
<input type="file" name="topper_copy" onChange={onChangeHandler}/>
<button type="button" className="btn "> Upload</button>

</div> */}
{/* 
<div className="col-12">

<label htmlFor="answer_copy" className="email">Answer Key PDF Upload </label>
<input type="file" name="answer_key" onChange={onChangeHandler}/>
<button type="button" className="btn "> Upload</button>

</div> */}


</div>
</>
  )

  
}


export default Student;