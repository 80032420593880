import React, { Component, Fragment } from "react";
import axios from 'axios';
import Spin from '../../../Component/spinner';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import "./home.css";

const data = [
  {
    name: 10,
    // uv: 4000,
    scores: 27,
    amt: 2400
  },
  {
    name: 20,
    // uv: 3000,
    scores: 52,
    amt: 2210
  },
  {
    name: 30,
    // uv: 2000,
    scores: 77,
    amt: 2290
  },
  {
    name: 40,
    // uv: 2780,
    scores: 98,
    amt: 2000
  },
];

const data01 = [
  { name: "Active", value: 400 },
  { name: "Inactive", value: 300 }
];

const data02 = [
  { name: "Male", value: 2400 },
  { name: "Female", value: 4567 },
];

class Home extends Component {
  // constructor(props){
  //   super(props);
  state = {
    loading:false,
    'purchases': {
      'total': 0,
      'issued': 0,
      'left': 0,
      'accessed': 0,
      'male': 0,
      'female': 0,
      'unaccessed': 0
    },
    'data01': [
      { name: "Active", value: 0 },
      { name: "Inactive", value: 0 }
    ],
    'data02': [
      { name: "Male", value: 0 },
      { name: "Female", value: 0 },
    ],
    'data': [
      {
        name: 10,
        // uv: 4000,
        scores: 0,
        amt: 2400
      },
      {
        name: 20,
        // uv: 3000,
        scores: 0,
        amt: 2210
      },
      {
        name: 30,
        // uv: 2000,
        scores: 0,
        amt: 2290
      },
      {
        name: 40,
        // uv: 2780,
        scores: 0,
        amt: 2000
      },
    ]
  }
  // }

  getResult(token) {
    const headers = {
      "Content-type": "application/json",
      "AUTHORIZATION": `Token ${token}`
    };

    axios.get(`${process.env.REACT_APP_API_URL}learning/api/learning/result/`, { headers: headers })
      .then(response => {
        console.log(response.data[0].quizResultCount);
        var dataEntry = []
        let entries = Object.entries(response.data[0].quizResultCount);
        if (entries) {
        for (let [index, [key, value]] of entries.entries()) {
          
            if (value != 0) {
              dataEntry.push({
                name: (index+1)*10,
                scores: value,
                amt: value
                    })
        }
        }
        this.setState({
          data:dataEntry
        })
      }
      })
      .catch(error => {
        console.log(error);
        this.setState({ error: true });
      });
  }


  componentDidMount() {
    
    var token = localStorage.getItem("token")
    const headers = {
      "Content-type": "application/json",
      "AUTHORIZATION": `Token ${token}`
    };

    this.getResult(token);

    axios.get(`${process.env.REACT_APP_API_URL}license/api/license/`, { headers: headers })
      .then(response => {
        // console.log(response);
        if (response.data) {
          this.setState({
            'purchases': {
              'total': response.data.total_license,
              'issued': response.data.license_issued,
              'left': response.data.license_accessed,
              'accessed': response.data.license_accessed,
              'female': response.data.license_female,
              'male': response.data.license_male,
              'unaccessed': response.data.license_unaccessed
            },
            'data01': [
              { name: "Active", value: response.data.license_accessed },
              { name: "Inactive", value: response.data.license_unaccessed }
            ],
            'data02': [
              { name: "Male", value: response.data.license_male },
              { name: "Female", value: response.data.license_female },
            ]
          })
          this.setState({loading:true})
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ error: true });
      });
  }


  static jsfiddleUrl = "https://jsfiddle.net/alidingling/30763kr7/";
  render() {
    console.log(this.state);
    return (
      <div>
        {/* // <Fragment> */}
        <div class="search-box py-2 ">
          <div class="row mx-0 px-0 my-0 py-0">
            <div class="col-lg-3 col-md-3 col-sm-4 text-center text-lg-left text-md-left text-sm-left mt-2">
              Home
            </div>
          </div>
        </div>
        {!this.state.loading&&<div style={{ position : 'absolute',
    top : '50%',
    left : '50%',
    transform : 'translate(-50%, -50%)',}}>
               
               <Spin/>   
           </div>}
        {this.state.loading&&
        <div class="col p-0 record-area">
          <div className="container">
            <div class="row record-card">
              <div class="col-md-6 col-lg-4 col-12 col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">Licenses Issue Records</p>
                    <div class="row">
                      <div class="col pt-3">
                        <h1>{this.state.purchases ? this.state.purchases.total : 'Coming Soon'}</h1>
                        <span>Purchases</span>
                      </div>
                      <div class="col">
                        <h3>{this.state.purchases ? this.state.purchases.issued : 'Coming Soon'}</h3>
                        <p>Issued</p>
                        <h3>{this.state.purchases ? this.state.purchases.left : 'Coming Soon'}</h3>
                        <p>left</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 col-12 col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">License Access Records</p>
                    <div class="row">
                      <div class="col pt-4">
                        <h1>{this.state.purchases ? this.state.purchases.issued : 'Coming Soon'}</h1>
                        <span>Issued</span>
                      </div>
                      <div class="col">
                        <h3>{this.state.purchases ? this.state.purchases.accessed : 'Coming Soon'}</h3>
                        <p>Accessed</p>
                        <h3>{this.state.purchases ? this.state.purchases.unaccessed : 'Coming Soon'}</h3>
                        <p>Unaccessed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 col-12 col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">Male vs Female </p>
                    <div class="row p-0">
                      <div class="col pt-4">
                        <h1>{this.state.purchases ? this.state.purchases.accessed : 'Coming Soon'}</h1>
                        <span>Demographics</span>
                      </div>
                      <div class="col">
                        <h3>{this.state.purchases ? this.state.purchases.male : 'Coming Soon'}</h3>
                        <p>Male</p>
                        <h3>{this.state.purchases ? this.state.purchases.female : 'Coming Soon'}</h3>
                        <p>Female</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="graph-section px-0">
            <div className="container">
              <div class="row p-0">
                <div class="col-md-6 col-12 col-sm-6 my-2">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title">
                        Active vs Inactive{" "}
                      </h6>
                      {/* <p>
                        <i class="fas fa-square-full px-2 square1"></i>Male{" "}
                        <i class="fas fa-square-full px-2 square2"></i> Female
                      </p> */}
                      <PieChart width={400} height={400}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={this.state.data01}
                          cx={200}
                          cy={200}
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        />
                        {/* <Pie
                          dataKey="value"
                          data={data02}
                          cx={500}
                          cy={200}
                          innerRadius={40}
                          outerRadius={80}
                          fill="#82ca9d"
                        /> */}
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 col-sm-6 my-2">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title">
                        Male vs Female{" "}
                      </h6>
                      {/* <p>
                        <i class="fas fa-square-full px-2 square1"></i>Male{" "}
                        <i class="fas fa-square-full px-2 square2"></i> Female
                      </p> */}
                      <PieChart width={400} height={400}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={this.state.data02}
                          cx={200}
                          cy={200}
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        />
                        {/* <Pie
                          dataKey="value"
                          data={data02}
                          cx={500}
                          cy={200}
                          innerRadius={40}
                          outerRadius={80}
                          fill="#82ca9d"
                        /> */}
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 col-sm-6 my-2">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title">
                        Quiz Scores{" "}
                      </h6>
                      <p>
                        {/* <i class="fas fa-square-full px-2 square1"></i>tttt{" "}
                        <i class="fas fa-square-full px-2 square2"></i> Female */}
                      </p>
                      <BarChart
                        width={500}
                        height={300}
                        data={this.state.data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="scores" fill="#8884d8" />
                        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                      </BarChart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
  }

        {/* // </Fragment> */}
      </div>
    );
  }
}

export default Home;
