import React from 'react';
import axios from 'axios';
import '../Branch/BranchCreate.css';
import Subs from "./SubSection_List";
import {Link} from "react-router-dom"
import Spinner from '../../../Component/spinner';
import {message} from "antd"
class SubSection extends React.Component {
  constructor(props){
  super(props);
    this.state = {
      title : '',
      description : '',
      video_url : '',
      content_pdf : '',  
      image : '',
      is_downloadable : "False",
      Chapter : '',
      data:[],
      prev:'',
      load:false,
  }
  }
  componentDidMount(){
    if (this.props.location.prev!=undefined){
      localStorage.setItem('prev',this.props.location.prev)
    }
    this.setState({...this.state,prev:localStorage.getItem('prev')});
    const token = localStorage.getItem('token')
    console.log(token)
    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    console.log(this.state)
    const id=this.props.location.state;
    this.setState({Chapter:this.props.location.state})


    axios.get(`${process.env.REACT_APP_API_URL}learning/api/add-section/?Chapter=${id}`, {headers : headers})
    .then(response => {
      
       this.setState({data:response.data.data})
    })
    .catch(err => {
      console.log(err)
    });
  }

  // onFormSubmit = (event) => {
  //     event.preventDefault();
  //     // window.location.href = "/license/";
  //     const data = {
  //       title : this.state.title,
  //       description : this.state.description,
  //       video_url : this.state.video_url,
  //       content_pdf : this.state.content_pdf,      
  //       image : this.state.image,
  //       is_downloadable:this.state.is_downloadable,
  //       Chapter : this.state.Chapter,
  //       //mobile : JSON.parse(this.state.mobile),      

  //     }
  //     console.log(data)
  //     const token = localStorage.getItem('token')
  //     console.log(token)
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "AUTHORIZATION": `Token ${token}`  
        
  //     }
  //     console.log(this.state)
  //     console.log(data)

  //     axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-section/`, data, {headers : headers})
  //     .then(response => {
  //       // if(response.data.token){
  //       //   window.location.reload();
  //       // }
  //         // }
  //         console.log(response)
       
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     });
      
  // }
  onChange = e => {
    this.setState({...this.state,[e.target.name]: e.target.value})
    console.log(this.state)
  }


  // rerenderme = ()=>{
  //   const token = localStorage.getItem('token')
  //   console.log(token)
  //   const headers = {
  //     "Content-Type": "application/json",
  //     "AUTHORIZATION": `Token ${token}`  
      
  //   }
  //   console.log(this.state)
  //   const id=this.props.location.state;
  //   this.setState({Chapter:this.props.location.state})


  //   axios.get(`${process.env.REACT_APP_API_URL}learning/api/add-section/?Chapter=${id}`, {headers : headers})
  //   .then(response => {
      
  //      this.setState({data:response.data.data})
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   });
  // }


  // }
 
onClickHandler = (e) => {
  e.preventDefault();
  this.setState({...this.state,load:true})
  const data = new FormData() 
  
  data.append('title',  this.state.title)
  if(this.state.description!='')data.append('description', this.state.description)
  if(this.state.video_url!='')data.append('video_url', this.state.video_url)
  if(this.state.content_pdf.name!=undefined)data.append('content_pdf', this.state.content_pdf,this.state.content_pdf.name)
  if(this.state.image.name!=undefined)data.append('image',  this.state.image,this.state.image.name)
  data.append('is_downloadable', this.state.is_downloadable)
  data.append('Chapter',this.state.Chapter)

  console.log(data)

const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.post(`${process.env.REACT_APP_API_URL}learning/api/add-section/`, data, {headers : headers})
.then(response => {
   if(response){
     window.location.reload();
    // this.rerenderme()
   }
    // }
 
})
.catch(err => {
  console.log(err)
  this.setState({load:false})
  message.warning("Some error occured")


});

}
  
onChangeHandler=event=>{

  this.setState({
    [event.target.name]: event.target.files[0],
  })
  console.log(event.target.files[0])
  console.log(event.target.name)


}

  render () {
    return (
      <div className="p-0">
      
{console.log(this.props)}

<div className="search-box py-2 px-2">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                  {/* <Link className="text-white" to="/section/">Subjects</Link><span className="px-3 tags"></span><Link className="text-white" to={`/${this.props.match.params.id}`}>{this.props.match.params.id}</Link><span className="px-3"></span>Sub Sections */}
                  <ul className="breadcrumbs">
  <li><Link className="text-white" to="/section/">Subjects</Link></li>
  {/* <li><Link className="text-white" to={`/${this.props.match.params.id}`}> */}
  <li><Link className="text-white" to=
  {{
     pathname: `/${this.props.match.params.id}`,
     state: this.state.prev
}}>
    
    {this.props.match.params.id}</Link></li>
  <li ><Link disabled className="text-white">Sub Sections</Link></li>
 
</ul>
                  </div>
                

                 </div>
              </div>

<div className="row">
<div className="col-lg-4">
              <div className="card">
  <div className="card-body " style={{cursor: "pointer"}}  data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Add Sub Section

  </div>
</div>
</div>
{this.state.data.map((d)=><Subs Chapter={this.props.location.state} key={d.id} id={d.id} title={d.title} description={d.description} video_url={d.video_url} rstp_url={d.rstp_url} content_pdf={d.content_pdf} image={d.image} is_downloadable={d.is_downloadable}/>
)}

{/* <Subs sub={this.props.match.params.id} id="a" name="Algebra 1"/>
<Subs sub={this.props.match.params.id} id="b" name="Algebra 2"/>
<Subs sub={this.props.match.params.id} id="c" name="Algebra 3"/> */}


 


</div>



      {/* <div className="row search-nav">
                <div className="col-sm-4">
                    <p> License Records</p>
                </div>
                <div className="col-sm-3 m-auto">

                    <button type="button" class="btn p-0 m-0 btn-sm pop-btn" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fas fa-plus mr-1"></i>Add License
                    </button>

                </div>
                <div className="col-sm-4 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><i class="fas fa-search"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div> */}
        {/* **************************** */}
 


      








<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Sub Section</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">




      {this.state.load? (<div className="loading">
  <Spinner/>
  </div>):("")}

    <div className="row">
        <div className="col m-auto p-3 form-div">
          {/* <div className="row">
            <h3>Add License</h3>
            <div className="col cancel">
              <i className="fas fa-times pr-2"></i>Cancel
            </div>
          </div> */}
{/* 
      <form onSubmit={(event) => this.onFormSubmit(event)}>

      <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="branch" className="email">Branch Id</label>
                  <input type="number" className="form-control" name="branch" id="branch" aria-describedby="emailHelp" placeholder="Branch id"
                  required="required"
                  value={this.state.branch}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


         <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="first_name" className="email">Name</label>
                  <input type="text" className="form-control" name="first_name" id="first_name" aria-describedby="emailHelp" placeholder="Name"
                  required="required"
                  value={this.state.first_name}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="mobile" className="email">Mobile</label>
                  <input type="number" className="form-control" name="mobile" id="mobile" aria-describedby="emailHelp" placeholder="Mobile"
                  required="required"
                  value={this.state.mobile}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="email" className="email">Email</label>
                    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Email" required="required"
                     data-error="Please enter a valid email."
                    value={this.state.email}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


        <div className="row">
              <div className="col">
                  <center> <button type="submit" className="btn">Save</button></center> 
              </div>
        </div>



      </form> */}





<form onSubmit={this.onClickHandler}>


<div className="row">
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="title" className="email">Title</label>
            <input type="text" className="form-control" name="title" id="title" aria-describedby="title" placeholder="Title"
            required="required"
            value={this.state.title}
            onChange={this.onChange}/>
          </div>
      </div>



    <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="description" className="email">Description</label>
            <input type="text" className="form-control" name="description" id="description" aria-describedby="description" placeholder="Description"
            value={this.state.description}
            onChange={this.onChange}/>
          </div>
      </div>
    </div>


    <div className="row">

    <div className="col-md-12">
          <div className="form-group">
              <label htmlFor="video_url" className="email">Video Link</label>
              <input type="text" className="form-control" name="video_url" id="video_url" aria-describedby="video_url" placeholder="video url" 
               
              value={this.state.video_url}
              onChange={this.onChange}/>
            </div>
      </div>

</div>
<div className="row">
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="content_pdf" className="email">PDF</label>
            <input type="file"  name="content_pdf" onChange={this.onChangeHandler}/>
            
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="image" className="email">Image</label>
            <input type="file" name="image" onChange={this.onChangeHandler}/>
         
          </div>
      </div>
    </div>



        <div className="col">
            <center> <button type="submit" className="btn btn-info "  >Save</button></center> 
        </div>



</form>






        </div>
        
    </div>





    </div>

</div>
</div>
</div>


      </div>
    )
  }
}



export default SubSection;