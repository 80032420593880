import React from 'react';
import axios from 'axios';


class CompanyCreate extends React.Component {
  state = {
      name: '',
      address : '',
      country : '',
      state : '',
      city : '',
      zip_code:'',
      active: false,
  }

  onFormSubmit = (event) => {
      event.preventDefault();
      this.props.history.push("/branch/");
      const data = {
        name :this.state.name,
        address : this.state.address,
        country : this.state.country,
        state : this.state.state,
        city:this.state.city,
        zip_code:this.state.zip_code,
        active: this.active,
      }
      const token = localStorage.getItem('token')
      console.log(token)
      const headers = {
        "Content-Type": "application/json",
        "AUTHORIZATION": `Token ${token}`
        
        
        
      }

      axios.post(`${process.env.REACT_APP_API_URL}companies/api/company/`, data, {headers : headers})
      .then(response => {
        console.log(response)
      })
      .catch(err => {
        console.log(err)
      });
      
  }
  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  onCheckBoxChange = e => {
    this.setState({active:!this.state.active})
  }

  render () {
    return (
      <div className="container">
      <div className="row">
          <div className="col-md-5 m-auto p-3 form-div">
            <div className="row">
              <h3>Add Company</h3>
              <div className="col cancel">
                <i className="fas fa-times pr-2"></i>Cancel
              </div>
            </div>
  
      <form onSubmit={(event) => this.onFormSubmit(event)}>

      <div className="row">
            <div className="col">
                <div className="form-group">
                  <label for="name" className="email">Branch Name</label>
                  <input type="text" className="form-control" name="name" id="name" aria-describedby="emailHelp" placeholder="Branch Name"                    
                  required="required"
                  value={this.state.name}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label for="city" className="email">Branch City</label>
                    <input type="text" className="form-control" name="city" id="city" aria-describedby="emailHelp" placeholder="City"
                    required="required"
                    value={this.state.city}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label for="state" className="email">Branch State</label>
                  <input type="text" className="form-control" name="state" id="state" aria-describedby="emailHelp" placeholder="State"
                  required="required"
                  value={this.state.state}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label for="zip_code" className="email">Branch Zip code</label>
                    <input type="text" className="form-control" name="zip_code" id="zip_code" aria-describedby="emailHelp" placeholder="Zip Code"
                    required="required"
                    value={this.state.city}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label for="address" className="email">Address</label>
                  <input type="text" className="form-control" name="address" id="address" aria-describedby="emailHelp" placeholder="Address"
                  required="required"
                  value={this.state.address}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label for="country" className="email">Country</label>
                  <input type="text" className="form-control" name="country" id="country" aria-describedby="emailHelp" placeholder="Country"
                  required="required"
                  value={this.state.country}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
                <span className="mt-4">Is Active ?</span> 
                <label className="switch">
                    <input type="checkbox" 
                    value={this.state.isheadOfficer} 
                    onChange={this.onCheckBoxChange}
                    />
                    <span className="slider round"></span>
                  </label>
            </div>
          </div>

          <div className="row">
              <div className="col">
                  <center> <button type="submit" className="btn">Save</button></center> 
              </div>
        </div>

      </form>
      </div>
       </div>
      </div>
    )
  }
}



export default CompanyCreate;