import React from 'react';
import { Link } from 'react-router-dom';

class ThankyouLogout extends React.Component {
    render () {
        return (
            // <h1>Thank you for spending time on Site. <Link to="/login/">Login</Link> Again. </h1>
            <div>
                <div className="jumbotron text-center">
                    <h1 className="display-3">Thank You!</h1>
                    <p className="lead"><strong>Please check your email</strong> for further instructions on how to complete your account setup.</p>
                    <br></br>
                    <p>
                     <h5> Want to spend time on the site <strong>again?</strong></h5><br />
                        Having trouble? <a href="">Contact us</a>
                    </p>
                    <Link to = "/">
                    <p className="btn btn-primary btn-sm">
                       Continue to homepage
                    </p>
                    </Link>
                    </div>
            </div>
        )
    }
}

export default ThankyouLogout;