import React from 'react';
import axios from 'axios';
import LicenseList from './LicenseList';
import '../Branch/BranchCreate.css';
import Spinner from '../../../Component/spinner';
import Chip from '@material-ui/core/Chip';
class License extends React.Component {
  state = {
      first_name : '',
      // username : '',
      email : '',
      mobile : '',  
      batch : '',
      branch : '',
      gender : 'M',
      subscription : [],
      subscription_show : [],

      subscriptionplans:[],
      selectedFile: null,
      load:false,
      error_message:null,
      bulk_error_message:null,

   
  }
componentDidMount()
{
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": "application/json",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  axios.get( `${process.env.REACT_APP_API_URL}subscription/api/subscription-plans/`, {headers:headers} )
  .then( response => {
      this.setState({subscriptionplans:response.data.data});
      let  d=response.data.data[0].id
      this.setState({subscription:this.state.subscription.concat(d)})

  } )
  .catch(error => {
      console.log(error);
      this.setState({error: true});
  });
}


// bulk code
onChangeHandler=event=>{

  this.setState({
    selectedFile: event.target.files[0],
  })

}

//UnderTest-Dropdown Subscription plans

handleChange = (e) => {
  let target = e.target
  let name = target.name

  //here
  let value = Array.from(target.selectedOptions, option => option.value);
    this.setState({
      subscription: value
    });
    let names = Array.from(target.selectedOptions, option => {

   return ( option.text)

  });
    this.setState({
      subscription_show: names
    });
    
 
}

//ends here

onClickHandler = (event) => {
  event.preventDefault();
  this.setState({load:true})
  const data = new FormData() 
  data.append('students_excel', this.state.selectedFile,this.state.selectedFile.name)
 

const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}

axios.post(`${process.env.REACT_APP_API_URL}license/api/bulk-license/`, data, {headers : headers})
.then(response => {
  if(response.data.status=='success'){
    window.location.reload();
   }
   else{
    this.setState({load:false,bulk_error_message:response.data.message})

   }
  // console.log(response.statusText)
})
.catch(err => {
  console.log(err)
  this.setState({load:false,bulk_error_message:'Some error occured..'})
});



}
//bulk end




  onFormSubmit = (event) => {
    this.setState({load:true})

      event.preventDefault();
      // window.location.href = "/license/";
      const data = {
        first_name : this.state.first_name,
        // username : this.state.username,
        email : this.state.email,
        mobile : this.state.mobile,      
        batch : this.state.batch,
        //subject : this.state.subject,
        // branch : this.state.branch,
        gender : this.state.gender,
        subscription : this.state.subscription,
        //        mobile : JSON.parse(this.state.mobile),      

      }
      const token = localStorage.getItem('token')
      const headers = {
        "Content-Type": "application/json",
        "AUTHORIZATION": `Token ${token}`  
        
      }

      axios.post(`${process.env.REACT_APP_API_URL}license/api/license/`, data, {headers : headers})
      .then(response => {
        if(response.data.token){
          window.location.reload();
        }
        else{
          this.setState({error_message:response.data.message,load:false})
        }
        // console.log(response)
      })
      .catch(err => {
        console.log(err)
        this.setState({load:false,error_message:'Some error occured'})
        


      });


      
  }

  onChange = e => {
    // if(e.target.name==='batch' && (e.target.value<2000 || e.target.value>new Date().getFullYear())){
    //   this.setState({batch:'2000'})
    // }
    // else{
    this.setState({[e.target.name]: e.target.value})
  // }
}

blur=e=>{
  if((e.target.value<2000 || e.target.value>new Date().getFullYear())){
    this.setState({[e.target.name]:2000})

}

}

  render () {
    return (
      <div className="p-0">

   
<div className="search-box py-2 px-2">
                <div className="row mx-0 px-0 my-0 py-0">

                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 text-center text-lg-left text-md-left text-sm-left mt-1 mb-1"> 
                    All License
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <button type="button" className="btn search-btn w-100 p-0 m-0 btn-sm" data-toggle="modal" data-target="#exampleModalCenter1">
                      <i className="fas fa-plus mr-2"></i>Bulk Issue License
                    </button>
                    {/* <select className="form-control select-field w-100">
                      <option>Select Fields</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select> */}
                  </div> 

                  <div className="col-lg-3 col-md-4 col-sm-6  col-6">

                    <button type="button" className="btn search-btn w-100 p-0 m-0 btn-sm" data-toggle="modal" data-target="#exampleModalCenter">
                      <i className="fas fa-plus mr-2"></i>Issue License
                    </button>

                  </div>

                
              
                </div>
              </div>










      {/* <div className="row search-nav">
                <div className="col-sm-4">
                    <p> License Records</p>
                </div>
                <div className="col-sm-3 m-auto">

                    <button type="button" class="btn p-0 m-0 btn-sm pop-btn" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fas fa-plus mr-1"></i>Add License
                    </button>

                </div>
                <div className="col-sm-4 m-auto">
                    <div className="serach">
                        <div className="input-group mb-3">     
                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"/>
                            <div className="input-group-append">
                                <a href=""><span className="input-group-text"><i class="fas fa-search"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div> */}
        {/* **************************** */}
 

{/* Bulk license code */}


<div className="modal fade" id="exampleModalCenter1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="col-5 modal-title" id="exampleModalLongTitle">Add Bulk License</h5>
        <a className="col-5 ml-5" href="https://imsspace2.ams3.digitaloceanspaces.com/imsspace2/imsspace2/media/bulk_license_excel/Bulk_license_sample.xlsx" download>Download Sample (.xlsx only)</a>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <form onSubmit={this.onClickHandler}>     

      <div className="row">
          {this.state.load?
       (<div className="loading">
      <Spinner/>
      </div>):("")
      }
        <div className="col-12 m-auto p-3 form-div">
       
          <input required type="file" name="file" onChange={this.onChangeHandler}/>
          </div>


          <div className="col-12">
          {this.state.bulk_error_message!=null &&<p className='text-center text-danger'> Error: {this.state.bulk_error_message}</p>}
          </div>

          <div className="col-12 m-auto p-3 form-div">
          <button type="submit" class="btn btn-success btn-block" >Upload</button> 

          </div>

</div>
</form>

</div>
</div>
</div>
</div>
      






<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add License</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

    <div className="row">
    {this.state.load?
       (<div className="loading">
      <Spinner/>
      </div>):("")
      }
        <div className="col m-auto p-3 form-div">
          {/* <div className="row">
            <h3>Add License</h3>
            <div className="col cancel">
              <i className="fas fa-times pr-2"></i>Cancel
            </div>
          </div> */}
{/* 
      <form onSubmit={(event) => this.onFormSubmit(event)}>

      <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="branch" className="email">Branch Id</label>
                  <input type="number" className="form-control" name="branch" id="branch" aria-describedby="emailHelp" placeholder="Branch id"
                  required="required"
                  value={this.state.branch}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


         <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="first_name" className="email">Name</label>
                  <input type="text" className="form-control" name="first_name" id="first_name" aria-describedby="emailHelp" placeholder="Name"
                  required="required"
                  value={this.state.first_name}
                  onChange={this.onChange}/>
                </div>
            </div>
          </div>


          <div className="row">
            <div className="col">
                <div className="form-group">
                  <label htmlFor="mobile" className="email">Mobile</label>
                  <input type="number" className="form-control" name="mobile" id="mobile" aria-describedby="emailHelp" placeholder="Mobile"
                  required="required"
                  value={this.state.mobile}
                  onChange={this.onChange}/>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="email" className="email">Email</label>
                    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Email" required="required"
                     data-error="Please enter a valid email."
                    value={this.state.email}
                    onChange={this.onChange}/>
                  </div>
            </div>
          </div>


        <div className="row">
              <div className="col">
                  <center> <button type="submit" className="btn">Save</button></center> 
              </div>
        </div>



      </form> */}





<form onSubmit={(event) => this.onFormSubmit(event)}>


<div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="first_name" className="email">Full Name</label>
            <input type="text" className="form-control" name="first_name" id="first_name" aria-describedby="first_name" placeholder="Full Name"
            required="required"
            value={this.state.first_name}
            onChange={this.onChange}/>
          </div>
      </div>
      </div>


{/* 
    <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="username" className="email">UserName</label>
            <input type="text" className="form-control" name="username" id="username" aria-describedby="username" placeholder="User Name"
            required="required"
            value={this.state.username}
            onChange={this.onChange}/>
          </div>
      </div>
    </div> */}


    <div className="row">

    <div className="col-md-6">
          <div className="form-group">
              <label htmlFor="email" className="email">Email</label>
              <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Email" required="required"
               data-error="Please enter a valid email."
              value={this.state.email}
              onChange={this.onChange}/>
            </div>
      </div>



      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="mobile" className="email">Mobile</label>
            <input type="number" className="form-control" name="mobile" id="mobile" aria-describedby="mobile" placeholder="Mobile Number"
            required="required"
            value={this.state.mobile}
            onChange={this.onChange}/>
          </div>
      </div>
    </div>


    {/* <div className="row">
          <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="batch" className="email">Batch Name</label>
            <input type="text"  pattern="[0-9]{4}" title="Please Enter 4 digit value" className="form-control" name="batch" id="batch" aria-describedby="batch" placeholder="Batch Name"
            required="required"
            value={this.state.batch}
            onChange={this.onChange}/>
          </div>
      </div> */}




           <div className="row">
          <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="batch" className="email">Batch Name</label>
            <input type="number" min="2000" max={new Date().getFullYear()}className="form-control" name="batch" id="batch" aria-describedby="batch" placeholder="Batch Name"
            required="required"
            value={this.state.batch}
            onChange={this.onChange}
            onBlur={this.blur}/>
            
          </div>
      </div> 
      {/*
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="subject" className="email">Subject</label>
            <input type="number" className="form-control" name="subject" id="subject" aria-describedby="subject" placeholder="Subject"
            required="required"
            value={this.state.subject}
            onChange={this.onChange}/>
          </div>
      </div>
    </div>
      */}

    {/* <div className="row"> */}
    {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="branch" className="email">Branch</label>
            <input type="number" className="form-control" name="branch" id="branch" aria-describedby="branch" placeholder="branch id"
            required="required"
            value={this.state.branch}
            onChange={this.onChange}/>
          </div>
      </div> */}



      <div className="col-md-6">
          <div className="form-group">
          <label htmlFor="gender" className="email">Gender</label>
            <select id="gender" name="gender" className="form-control"  required="required"
            value={this.state.gender}
            onChange={this.onChange}>
              <option name='gender' value="M">Male</option>
              <option name='gender' value="F">Female</option>

            </select>
            {/* <label htmlFor="gender" className="email">Gender</label>
            <input type="text" className="form-control" name="gender" id="gender" aria-describedby="gender" placeholder="Gender"
            required="required"
            value={this.state.gender}
            onChange={this.onChange}/> */}
          </div>
      </div>
    </div>




    

    <div className="row">
    <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="subscription" className="email">subscription</label>
            {/* <select id="subscription" name="subscription" className="form-control"  required="required"
            value={this.state.subscription}
            onChange={this.onChange}>
              {this.state.subscriptionplans.map(data=><option name='subscription' value={data.id}>{data.name}</option>)}
             

            </select> */}

          <select style={{width:"100%"}} id="subscription" name="subscription" multiple={true} onChange={this.handleChange}  value={this.state.selectOptions} >
          {this.state.subscriptionplans.map(data=><option name={data.name} value={data.id}>{data.name}</option>)}

        </select>



            {/* <input type="number" className="form-control" name="subscription" id="subscription" aria-describedby="subscription" placeholder="subscription id"
            required="required"
            value={this.state.subscription}
            onChange={this.onChange}/> */}
          </div>
      </div>
      {/* {this.state.subscription_show.join(', ')} */}
            <div className="col-md-12">

      {this.state.subscription_show.map((name)=>
    <Chip size="small" label={name} />)
  }

</div>
</div>

<div className="col">
{this.state.error_message!=null &&<p className='text-center text-danger'> Error: {this.state.error_message}</p>}
</div>
        <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>


</form>






        </div>
        
    </div>





    </div>

</div>
</div>
</div>


       <LicenseList/>
      </div>
    )
  }
}



export default License;