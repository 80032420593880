import React, { Component } from 'react';
import ComplainDetail from './ComplainDetail';
import axios from 'axios';

class Complain extends Component {
    state = {
        complain_list:[],
        selectedComplainDetailId:null,
        error:false,
    }
    componentDidMount(){
        const token = localStorage.getItem('token')
        const headers = {
            "Content-Type": "application/json",
			"AUTHORIZATION": `Token ${token}`
        }
        axios.get( `${process.env.REACT_APP_API_URL}complaints/api/complain/`, {headers:headers} )
        .then(response => {
            this.setState({complain_list:response.data});
            console.log( response.data );
        })
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }
    onComplainDetailClick(id){
        this.setState({selectedComplainDetailId:id});
    }
    render() {
        let complain_list = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            complain_list = this.state.complain_list.map(complaint => {
                return <ComplainDetail
                key={complaint.complain_id} 
                sno={complaint.complain_id}
                name={complaint.complaint_by}
                severity={complaint.severity}
                date={complaint.created_date}
                repeated={complaint.repeated}
                accussed_name={complaint.accussed_name}
                accussed_department={complaint.accussed_department}
                accussed_position={complaint.accussed_position}
                description={complaint.description}

                clicked={()=> this.onComplainDetailClick(complaint.complain_id)}
                />
            })
        }
        return (
            <div>
                
                {complain_list}
              
            </div>
        )
    }
}

export default Complain
