import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../Component/spinner';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';
import './Login.css';

class Login extends React.Component {
    state = {username: "",
            password: ""}

    onFormSubmit = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.username, this.state.password, this.props)
        
        
        
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    render() {
        const {username, password } = this.state;
        let login = <h2 className="heading"><center>Login</center></h2>
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 col-md-3 m-auto">
{login}
                {this.props.error ? 'Invalid data' : ''}
                {this.props.loading ? 
                <Spinner/>
                
            :

            <form onSubmit={this.onFormSubmit}>
              <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="email">Username</label>
                        <input type="text" name='username' className="form-control" id="exampleInputEmail1" placeholder="Username"
                        required="required"
                          value={username}
                          onChange={this.onChange}/>
             </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="email">Password</label>
                <div className="row">
                    <div className="col-sm-9"><input 
                        type="password" name='password' className="form-control" 
                        id="exampleInputPassword1" placeholder="*********"  required="required"
                        value={password} 
                        onChange={this.onChange}
                        /></div>
                    {/* <div className="col-sm-2"><i className="far fa-eye-slash"></i></div> */}
                </div> 
              </div>

              {/* <div className="row  remember-text">
                    <div className="col-sm-12">
                        <div className="checkbox">
                            <label><input type="checkbox"/> Remember Password</label>
                        </div>
                    </div>
                    <div className="col-sm-6 text-right">
                    <Link to="/"> Forgot Password</Link> 
                    </div>
             </div> */}
              <button type="submit" className="btn btn-block">Login</button>
            </form>}
            {/* <p className="sign-up">Don't have an account? <Link to="/"> Signup</Link></p> */}

                    </div>
               </div>         
            </div>    
        )
    }
}


const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password, props) => dispatch(actions.authLogin(username, password,props)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);