import React from 'react';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './Navigation';
import "./style.css"
import 'pdf-viewer-reactjs-bulma-wrapped/css/main.css';

class App extends React.Component {

    componentDidMount() {
      if(document.location.pathname!='/' && localStorage.getItem('token')===null){
        window.location.replace("/");
      }
     
        this.props.onTryAutoSignup();
      }
    

    render () {
        return (
            <BrowserRouter>
                <Navigation {...this.props}/>
            </BrowserRouter>  
        )
    }
} 


const mapStateToProps = state => {
    return {
      isAuthenticated: state.token !== null
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }
    
    export default connect(mapStateToProps, mapDispatchToProps)(App);