import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';
import {Spin,Button,message} from "antd"
function Licensetable  (props) {
    const [data,SetData] = useState({
        name:'',
        license:props.license,
        email:'',
        gender:'',
        mobile:'',
        batch:'',
        status:props.status,
        plans:[],
        myplans:[],
        currentplans:[],
        subscriptionplans:props.plans,
       
        
      });
      const [open,setOpen] = useState(false)
      const [loaded,setLoader] = useState(true)
      const [planapi,setPlanning]=useState(true)
useEffect(()=>
{

    const token = localStorage.getItem('token')
    const headers = {
      // "Content-Type": "multipart/form-data",
      "AUTHORIZATION": `Token ${token}`  
      
    }
    setLoader(false)
    axios.get(`${process.env.REACT_APP_API_URL}license/api/get-plans/?id=${props.email}`, {headers : headers})
    .then(response => {
        // SetData({...data,plans:response.data.subscription})
        const dd=response.data.subscription.map(d=>d.id)
        SetData({...data,myplans:dd,plans:response.data.subscription,currentplans:response.data.subscription})
        // }
        setLoader(true)
    })
    .catch(err => {
      console.log(err)
    });

},[open])
   
  const resend =()=>{
    const token = localStorage.getItem('token')
    const headers = {
      "AUTHORIZATION": `Token ${token}`  
    }
    const formdata = new FormData();
    formdata.append('email',props.email)
    message.loading('Resending mail..',0)
    axios.post(`${process.env.REACT_APP_API_URL}license/api/resend-license/`, formdata,{headers : headers})
    .then(response => {
       message.destroy()
       message.success("Mail sent.")
    })
    .catch(err => {
      console.log(err)
      message.destroy()
      message.error("Some error occured")
    });

  }


  const deleteMe=()=>
  {
   
  }


  const callMe=(e)=>
  {

    SetData({name:props.name,
    email:props.email,
    gender:props.gender,
    mobile:props.mobile,
    batch:props.batch,
    license:props.license,
    plans:props.plans,
    status:props.status
    })
    setOpen(!open)
  
  }

  const changed=(e)=>
  {
    SetData({...data,[e.target.name]:e.target.value});

  }

   

const showName=(e)=>{
  setPlanning(false)
const id = e.currentTarget.dataset.id
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
}
axios.delete(`${process.env.REACT_APP_API_URL}license/api/license/?id=${id}&email=${props.email}`, {headers : headers})
.then(response => {
  setOpen(!open)
  setPlanning(true)

    // if(response.statusText==='OK'){
    //   window.location.reload();
    // }
    // }
})
.catch(err => {
  console.log(err)
  setPlanning(true)

});


  // console.log(e.currentTarget.dataset.id);
  // console.log(e.currentTarget.dataset.name);
  // const d = data.plans.filter(x=>x.id!=e.currentTarget.dataset.id)
  // const da = d.map(x=>x.id)
  // SetData({...data,plans:d,myplans:da})

}

const handleChange = (e) => {
  let target = e.target
  let name = target.name

  //here
  let value = Array.from(target.selectedOptions, option => option.value);
   
    let names = Array.from(target.selectedOptions, option => {

   return ({id:option.value,name: option.text})
  
  });

  const inputs = new FormData() 
  inputs.append('first_name',  data.name)
  inputs.append('email',  data.email)
  inputs.append('mobile',  data.mobile)
  // inputs.append('gender',  data.gender)

  inputs.append('batch',  data.batch)
  inputs.append('subscription',value)
  
  // console.log(inputs)
  setPlanning(false)
const id = data.email
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
}
axios.put(`${process.env.REACT_APP_API_URL}license/api/license/?id=${id}`, inputs, {headers : headers})
.then(response => {
  setOpen(!open)
  setPlanning(true)

    // if(response.statusText==='OK'){
    //   window.location.reload();
    // }
    // }
})
.catch(err => {
  console.log(err)
  setPlanning(true)

});




//   console.log(value,names)
//   // console.log(target.selectedOptions[0].text,target.value)
//  const n=target.selectedOptions[0].text
//  const v=target.value
//  const d=data.plans.filter(x=>x.id!=v)
// const val = d.map(x=>x.id)
  // SetData({...data,
  //   myplans:value,plans:names
  // });
    
 
}

  
const showChange=(e)=>
{
// const stats =(e.target.checked?("True"):("False"))
// const stats =e.target.checked
const stats =(e.target.checked?('True'):('False'))

const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.post(`${process.env.REACT_APP_API_URL}license/api/license-status/?license=${data.license}&status=${stats}`,null, {headers : headers})
.then(response => {

    // }
 
})
.catch(err => {
  console.log(err)
});
(e.target.checked?(SetData({...data,status:true})):(SetData({...data,status:false})))

}

 const onClickHandler = () => {
  // window.location.reload();
  const inputs = new FormData() 
  inputs.append('first_name',  data.name)
  inputs.append('email',  data.email)
  inputs.append('mobile',  data.mobile)
  // inputs.append('gender',  data.gender)
  inputs.append('batch',  data.batch)
  
  // console.log(inputs)
  setPlanning(false)
const id = data.email
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
axios.put(`${process.env.REACT_APP_API_URL}license/api/license/?id=${id}`, inputs, {headers : headers})
.then(response => {
  
    if(response.statusText==='OK'){
      window.location.reload();
    }
    // }
})
.catch(err => {
  console.log(err)
  setPlanning(true)

});

}
    
    return(
<>

{/* delete */}
{/* 
<div className="modal fade" id={`del${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`Delete ${props.name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    <div className="row">
          
      <div className="col-md-12">
          <select>
         {data.plans!=undefined?data.plans.map(d=>(<option>{d.name}</option>))
         :("No Plans")}
         </select>
      </div>
    
      
      <div className="col">
            <center> <button type="submit" className="btn btn-info " onClick={onClickHandler}>Save</button></center> 
        </div>
        

</div></div>
</div>
</div>
</div>
 */}



{/* delete over */}

<div className="modal fade" id={`edit${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`Edit ${props.name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    {!planapi&&<Spin/>}
    <div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="license" className="email">License Number</label>
            <input type="text" className="form-control" name="license" id="license" aria-describedby="license"
            disabled value={data.license} />
          </div>
      </div>
       <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name" className="email">Full Name</label>
            <input type="text" className="form-control" name="name" id="name" aria-describedby="name"
            value={data.name} onChange={changed}  />
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="email" className="email">Email</label>
            <input type="text" className="form-control" name="email" id="email" aria-describedby="email"
            value={data.email} disabled />
          </div>
      </div>

      {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="gender" className="email">Gender</label>
            <input type="text" className="form-control" name="gender" id="gender" aria-describedby="gender"
            value={data.gender} onChange={changed} />
          </div>
      </div> */}
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="batch" className="email">batch</label>
            <input type="text" className="form-control" name="batch" id="batch" aria-describedby="batch"
            value={data.batch} onChange={changed}  />
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="mobile" className="email">Phone</label>
            <input type="text" className="form-control" name="mobile" id="mobile" aria-describedby="mobile"
            value={data.mobile} onChange={changed}  />
          </div>
      </div>


       <div className="col-md-12">

         
      <select style={{width:"100%"}} id="subscription" name="subscription"  onChange={handleChange}   >
        <option value="" disabled selected >Select Plan</option>
          {props.plans.map(data=><option name={data.name} value={data.id}>{data.name}</option>)}

        </select>
        </div> 
        {!loaded&&<div className='col-md-12'><Spin/></div>}
     {loaded&& <div className="col-md-12">
         {data.plans!=undefined?data.plans.map(d=><Chip  style={{margin:"2px"}} onClick={showName} data-id={d.plan_id}  data-name={d.name} label={d.name}/>)
         :("No Plans")}
      </div>}
    
      
      <div className="col">
            <center> <button type="submit" className="btn btn-info " onClick={onClickHandler}>Save</button></center> 
        </div>
        

</div></div>
</div>
</div>
</div>

{/* View start */}





<div className="modal fade" id={`view${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`View ${props.name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    <div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="license" className="email">License Number</label>
            <input type="text" className="form-control" name="license" id="license" aria-describedby="license"
            disabled value={data.license} />
          </div>
      </div>
       <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name" className="email">Name</label>
            <input type="text" className="form-control" name="name" id="name" aria-describedby="name"
           disabled value={data.name} />
          </div>
      </div>

      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="email" className="email">Email</label>
            <input type="text" className="form-control" name="email" id="email" aria-describedby="email"
           disabled value={data.email} />
          </div>
      </div>

      {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="gender" className="email">Gender</label>
            <input type="text" className="form-control" name="gender" id="gender" aria-describedby="gender"
           disabled value={data.gender} />
          </div>
      </div> */}
      {data.mobile!='undefined'&&
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="phone" className="email">Phone Number</label>
            <input type="text" className="form-control" name="phone" id="phone" aria-describedby="phone"
           disabled value={data.mobile} />
          </div>
      </div>
}
      <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="batch" className="email">batch</label>
            <input type="text" className="form-control" name="batch" id="batch" aria-describedby="batch"
           disabled value={data.batch} />
          </div>
      </div>
      {!loaded&&<div className='col-md-12'><Spin/></div>}
    
      {loaded&&<div className="col-md-12">
         {data.plans!=undefined?data.plans.map(d=><Chip style={{margin:"2px"}} label={d.name}/>)
         :("No Plans")}
      </div>}
    
    
</div></div>
</div>
</div>
</div>
               
                <tr>
                    <td>{props.name}</td>
                    <td>{props.license}</td>
                    {/* <td>{props.first_name}</td> */}
                    <td>{props.email}</td>
                    <td>
                    <div className='custom-control custom-switch'>
        <input
          type='checkbox'
          className='custom-control-input'
          id={`customSwitches${props.id}`}
          onChange={showChange}
          value={props.status}
          name={props.name}
          checked={data.status===true?(true):(false)}
        />
        <label className='custom-control-label' htmlFor={`customSwitches${props.id}`}>
        </label>
      </div>
                    </td>
                    <td>{props.active?( <i className="fa fa-eye" aria-hidden="true"></i>):(<Button onClick={resend}>Resend Mail</Button>)}</td>

                    {/* <td>{props.status==='True'?("Active"):("Inactive")}</td> */}
                    <td><span className="float-right" style={{cursor:"pointer"}} name={props.name}  data-toggle="modal" data-target={`#edit${props.id}`}  onClick={callMe}>  <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
</span>  <span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  data-toggle="modal" data-target={`#view${props.id}`} onClick={callMe}> <i  className="fa fa-eye" aria-hidden="true"></i> 

</span>


</td>
                    
                </tr>
            
</>
    )
    }

export default Licensetable;