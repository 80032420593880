import React from 'react';
import './CompanyCommitte.css';
import Table from '../../../Component/committeetable';
import axios from 'axios';

class CommitteeList extends React.Component {
    state = {
        committee : [],
        error : false
    }
    componentDidMount() {
        const token = localStorage.getItem('token')
        console.log(token)
        const headers = {
          "Content-Type": "application/json",
          "AUTHORIZATION": `Token ${token}`
          
        }
        axios.get( `${process.env.REACT_APP_API_URL}companies/api/committee/`, {headers:headers} )
        .then( response => {
            this.setState({committee:response.data});
            console.log( response.data );
        } )
        .catch(error => {
            console.log(error);
            this.setState({error: true});
        });
    }

    render() {
        let committee = <p style={{textAlign: 'center'}}>Something went wrong!</p>;
        if (!this.state.error) {
            committee = this.state.committee.map(c => {
                return <Table
                    key={c.id} 
                    sno={c.id}
                    name={c.name}
                    company={c.company}
                    email={c.email}
                    mobile={c.mobile}
                    />
                    
            });
    }
        return(
            <div className="row main-content">
            <div className="col">
            <table className="row-space">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Company</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email</th>
                </tr>
                </thead>
                {committee}
            </table>
            </div>
            </div>
        )
    }
}
export default CommitteeList