import React,{useState,useEffect} from 'react';
import axios from 'axios';
import LicenseList from './LicenseList';
import '../Branch/BranchCreate.css';
import Subject from './Subject';
import {Link} from "react-router-dom";
import Spinner from '../../../Component/spinner';
import {message} from "antd"
function Subject_List(props)
{
  const [data,SetData] = useState({
    course_name:'',



  });

  const [load,setLoad]=useState(false);

  const deleteMe=()=>
  {
   
  const id = props.id
  const token = localStorage.getItem('token')
  const headers = {
    // "Content-Type": "multipart/form-data",
    "AUTHORIZATION": `Token ${token}`  
    
  }
  console.log(id)
  axios.delete(`${process.env.REACT_APP_API_URL}learning/api/add-course/?id=${id}`, {headers : headers})
  .then(response => {
      if(response.statusText==='OK'){
        window.location.reload();
      }
      console.log(response)
      // }
   
  })
  .catch(err => {
    console.log(err)
  });
  }

  const callMe=(e)=>
  {

    SetData({course_name:props.course_name,
    })
    console.log(data)
  }

  const changed=(e)=>
  {
    SetData({[e.target.name]:e.target.value});
    console.log(data)

  }



  
const onClickHandler = (event) => {
  event.preventDefault();

  setLoad(true)

  const inputs = new FormData() 
  
  inputs.append('course_name',  data.course_name)
  console.log(inputs)

const id = props.id
const token = localStorage.getItem('token')
const headers = {
  // "Content-Type": "multipart/form-data",
  "AUTHORIZATION": `Token ${token}`  
  
}
console.log(inputs)
console.log(id)
axios.put(`${process.env.REACT_APP_API_URL}learning/api/add-course/?id=${id}`, inputs, {headers : headers})
.then(response => {
    if(response.statusText==='OK'){
      window.location.reload();
    }
    // }
 console.log(response)
})
.catch(err => {
  console.log(err)
  setLoad(false)
  message.warning("Some error occured")

});

}
return(
<>  

<div className="modal fade" id={`course${props.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitles" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
<h5 className="modal-title" id="exampleModalLongTitle">{`Edit ${props.course_name}`}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    {load?
  (<div className="loading">
  <Spinner/>
  </div>):("")
}
<form onSubmit={onClickHandler}>
    <div className="row">
      <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="course_name" className="email">Subject Name</label>
            <input type="text" className="form-control" name="course_name" id="course_name" aria-describedby="course_name"
            required="required" value={data.course_name} onChange={changed}/>
          </div>
      </div>
      <div className="col">
            <center> <button type="submit" className="btn btn-info ">Save</button></center> 
        </div>
        

</div>
</form>
</div>
</div>
</div>
</div>

<div className="col-lg-4">

              <div className="card">

  <div className="card-body">

  <Link className="text-dark" to={{
     pathname: `/${props.course_name}`,
     state: props.id,
     
}}>
{props.course_name}  </Link><span className="float-right" style={{cursor:"pointer"}} name={props.course_name}  data-toggle="modal" data-target={`#course${props.id}`} onClick={callMe}>  <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
</span>  <span  className="float-right" style={{cursor:"pointer" ,marginRight:"5px"}}  onClick={deleteMe}> <i  className="fa fa-trash" aria-hidden="true"></i> 

</span>
</div>

</div>
</div>
</>
  )

  
}


export default Subject_List;