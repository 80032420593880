import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { message } from "antd";
function Assignment_List(props) {
  const deleteMe = () => {
    message.loading("Deleting Assignment");
    const id = props.id;
    const token = localStorage.getItem("token");
    const headers = {
      // "Content-Type": "multipart/form-data",
      AUTHORIZATION: `Token ${token}`,
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}learning/api/test-series/?id=${id}`,
        { headers: headers }
      )
      .then((response) => {
        message.destroy();
        if (response) {
          message.success("Assignment deleted successfully");
          window.location.reload();
        }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-body">
            <Link className="text-dark" to={`/assignment/${props.id}`}>
              {props.name}{" "}
            </Link>

            <span
              className="float-right"
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={deleteMe}
            >
              {" "}
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Assignment_List;
